export const getNodeValue = (obj, path = "") => {
    if (!path) return obj;

    const keys = path.trim().split('.');
    let value = obj;

    for (const key of keys) {
        if (typeof value !== 'object' || value === null || !(key in value)) {
            return null;
        }
        value = value[key];
    }

    return value;
}

// export const replaceVariables = (string, object) => {
//     let type = typeof template

//     const variableStart = "{{";
//     const variableEnd = "}}";

//     const replaceAll = (str, find, replace) => str.split(find).join(replace);

//     const replaceRecursive = (str, obj) => {
//         for (const key in obj) {
//             const value = obj[key];
//             if (typeof value === "object") {
//                 str = replaceRecursive(str, value);
//             } else {
//                 const variable = variableStart + key + variableEnd;
//                 str = replaceAll(str, variable, value);
//             }
//         }
//         return str;
//     };

//     return replaceRecursive(string, object);
// }


export const replaceVariables = (template, variables) => {
    if (!template || !variables)
        return template

    let type = typeof template

    if (type == "object")
        template = JSON.stringify(template)

    let replacedString = template;
    for (let { regex, types } of [
        { regex: /{{(.*?)}}/g, types: ["string", "number", "boolean"] },
        { regex: /"{{(.*?)}}"/g, types: ["object"] }
    ]) {
        replacedString = replacedString.replace(regex, (match, variable) => {
            const trimmedVariable = variable.trim();
            const type = typeof variables[trimmedVariable];

            // console.log(match, variable)

            return variables.hasOwnProperty(trimmedVariable) && types.includes(type) ?
                (type == "object" ?
                    JSON.stringify(variables[trimmedVariable]) :
                    variables[trimmedVariable]
                ) :
                match;
        });

        // console.log("[replacedString]", replacedString)
    }
    let result = type == "object" ? JSON.parse(replacedString) : replacedString
    // console.log(result)

    return result
}


export const hasUndefinedVariables = (string, object) => {
    const variableStart = "{{";
    const variableEnd = "}}";

    const extractVariables = (str) => {
        const regex = new RegExp(`${variableStart}(.*?)${variableEnd}`, "g");
        return str.match(regex) || [];
    };

    const variables = extractVariables(string);

    for (const variable of variables) {
        const key = variable.slice(variableStart.length, -variableEnd.length);
        const keys = key.split(".");
        let value = object;
        for (const nestedKey of keys) {
            if (value[nestedKey] === undefined) {
                return true;
            }
            value = value[nestedKey];
        }
    }

    return false;
}