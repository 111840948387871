import React from 'react'

import "./styles.scss"
import Input from '../../../Input/Input'
import Button from '../../../Button'

export default function SelectField({
    data,
    setData,
    indexField,
    indexOption,
    totalSize,

    arrayAttr = "options",

    firstElementName = "name",
    firstElementText = "Nome",
    firstElementPlaceholder = "Nome",

    secondElementName = "value",
    secondElementText = "Valor",
    secondElementPlaceholder = "Valor do campo",

    referenceSizeToDelete = 1
}) {
    const setValue = (key, value) => {
        setData(data => {
            // data.fields[indexField].options = []
            // data.fields[indexField].options[indexOption][key] = value
            data.fields[indexField][arrayAttr][indexOption] = {
                ...data.fields[indexField][arrayAttr][indexOption],
                [key]: value
            }

            return { ...data }
        })
    }

    const remove = () => {
        setData(data => {
            data.fields[indexField][arrayAttr].splice(indexOption, 1)

            return { ...data }
        })
    }

    return (
        <div className="select-field">
            <div style={{ flex: 1 }}>
                <Input
                    name={firstElementName}
                    label={firstElementName}
                    placeholder={firstElementPlaceholder}
                    value={data.fields[indexField]?.[arrayAttr][indexOption]?.[firstElementName]}
                    onChange={e => setValue(firstElementName, e.target.value)}
                    waitTime={50}
                />
            </div>
            <div style={{ flex: 1 }}>
                <Input
                    name={secondElementName}
                    label={secondElementName}
                    placeholder={secondElementPlaceholder}
                    value={data.fields[indexField]?.[arrayAttr][indexOption]?.[secondElementName]}
                    onChange={e => setValue(secondElementName, e.target.value)}
                    waitTime={50}
                />
            </div>
            {totalSize > referenceSizeToDelete &&
                <div>
                    <Button
                        variant={"delete"}
                        icon="trash"
                        onClick={remove}
                        size="short"
                    />
                </div>
            }
        </div>
    )
}