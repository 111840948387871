const translations = {
    title: {
        homePage: 'Gerenciador de Recursos',
        exemples: 'Exemplos',
        systemInfo: 'Informações do sistema',
        userInfo: 'Informações do usuário',
        connectDatabase: 'Conectar Banco de Dados',
        description: 'Utilize o formulário abaixo para informar os dados de conexão do banco de dados MongoDB.',
    },
    link: {
        doc: "https://docs.sharkdev.com.br/blip/gerenciador_de_recursos/pt-BR",
        blipDataExample: 'Obtenha dados do blip',
        swrExemple: 'Buscar dados com SWR',
        pluginSettings: 'Configurações'
    },
    button: {
        repository: 'Visite o Repositório',
        connect: 'Conectar',
        disconnect: 'Desconectar',
        reconnect: 'Reconectar',
        document: 'Visite a Documentação',
        cancel: 'Cancelar',
        import: 'Importar',
        export: 'Exportar',
        createResource: 'Cadastrar Recurso',
        accessData: 'Acessar dados',
        accessDoc: 'Acessar documentação',
        importItems: 'Importar Itens',
        createItem: "Cadastrar Item",
        back: 'Voltar',
        done: 'Pronto',
        newField: 'Novo campo',
        newFieldObject: 'Novo campo do objeto',
        newOption: 'Nova opção',
        imSure: "Sim, tenho certeza",
        cancelImport: "Cancelar importação",
        replaceWithNewOnes: "Substituir pelos novos",
        keepThoseAlreadyRegistered: "Manter os já registrados"
    },
    texts: {
        itemSingular: 'item',
        itemPlural: 'itens',
        key: "Chave",
        attention: "Atenção",
        resource: "recurso",
        basicInformation: 'Informações Básicas',
        fields: "Campos",
        aboutFieldName: "Nome utilizado apenas para visualização",
        aboutFieldKey: "Utilizada para recuperar o valor do conteúdo pelo servidor",
        placeholderName: "Qual nome gostaria de dar para este recurso?",
        placeholderKey: "Chave do recurso",
        placeholderResourceType: "Qual o tipo do recurso?",
        objectFields: "Campos do objeto",
        options: "Opções",
        title: "Título",
        fieldTitle: "Título do campo",
        fieldKey: "Chave do campo",
        type: "Tipo",
        collection: "Coleção",
        single: "Único",
        eventTrack: "Registro de Evento",
        text: "Texto",
        textarea: "Parágrafo",
        select: "Seleção",
        textList: "Lista de Texto",
        objectList: "Lista de objetos",
        onOff: "On/Off",
        file: "Arquivo",
        selectHTTP: "Seleção por requisição HTTP",
        resourceType: "Tipo do Recurso",
        eventCategory: "Categoria do Evento",
        add: "Adicionar",
        itemsAndStructure: "Itens e Estrutura",
        justStructure: "Apenas a estrutura",
        justItems: "Apenas os itens",
        jsonFile: "Arquivo JSON",
    },
    menu: {
        goBack: 'Voltar'
    },
    name: 'Nome',
    shortName: 'Nome abreviado',
    email: 'E-mail',
    phone: 'Telefone',
    identity: 'Identificador',
    identifier: 'Identificador',
    language: 'Idioma',
    accessKey: 'Chave de acesso',
    template: 'Template',
    creationDate: 'Data de criação',
    appresentation: `<b>Gerenciador de Recursos</b> chegou para facilitar a vida do dev que precisa se conectar a um banco Mongo. Nada de API intermediária! Agora você pode contar com <b>Gerenciador de Recursos</b> 😍<br /><br />Com esta extensão, você conecta o Blip ao seu banco de dados MongoDB informando os dados de conexão e já pode sair usando no Builder.<br /><br />É fácil e rápido!`,
    guidelines: {
        // guideline_1: "Teste 1",
        // guideline_2: "Teste 2",
    },
    instance: {
        connection: {
            connected: "Banco de dados conectado",
            disconnected: "Banco de dados desconectado",
            failed: "A conexão com o Banco de Dados falhou",
        }
    },
    messages: {
        created: "Instância criada com sucesso",
        updated: "Instância atualizada com sucesso",
        connected: "Instância conectada com sucesso",
        disconnected: "Instância desconectada com sucesso",
        failed_connection: "A conexão com o Banco de Dados falhou",
        copy_to_clipboard: "Copiado para a área de transferência!",
        user_admin: "Apenas administradores podem acessar a extensão",
        deleteItem: "Tem certeza que deseja deletar este item? Você não conseguirá recuperá-lo depois.",
        copiedToClipboard: "Copiado para a área de transferência!",
        successfullyCopied: "O ID do item foi copiado com sucesso.",
        resourceDuplicated: "Um outro recurso com o mesmo ID já existe.",
        noResourceRegistered: "Nenhum recurso cadastrado até o momento",
        whatExport: "O que você deseja exportar?",
        useImportFile: 'Utilize um arquivo de importação. É necessário que o array esteja no atributo "itens"',
        noItemWasEntered: "Nenhum item foi inserido",
        duplicatedIDs: "Encontramos registros com ID idênticos. Como deseja continuar?"
    },
    forms: {
        required: "Campo obrigatório"
    },
    developedBy: "Desenvolvido por"
};

export default translations;
