import React from 'react';
import { AppProvider } from './AppContext';
import { SharkPluginsProvider } from './SharkPluginsProvider';
import { BlipProvider } from './BlipContext';

export default function Providers({ children }) {
    return (
        <SharkPluginsProvider>
            <AppProvider>
                <BlipProvider>
                    {children}
                </BlipProvider>
            </AppProvider>
        </SharkPluginsProvider>
    );
};