import React, { useContext, useEffect } from 'react';
import Button from '../../Button';

import "./styles.scss"
import Input from '../../Input/Input';
import { AppContext } from '../../../contexts/AppContext';

export default function TextList({
    label,
    data = [],
    setData,
    field
}) {
    const { t } = useContext(AppContext)

    const handleAddOption = () => {
        setData(data => {
            data = JSON.parse(JSON.stringify(data))

            data = [...data, ""]

            return data
        })
    }

    const setValue = (index, value) => {
        setData(data => {
            data[index] = value
            return [...data]
        })
    }

    const remove = (index) => {
        setData(data => {
            data.splice(index, 1)

            return [...data]
        })
    }

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <bds-typo variant="fs-16" tag="h2" translate="" className="hydrated">{label}</bds-typo>

                <Button
                    variant={"primary"}
                    text={t('texts.add')}
                    onClick={handleAddOption}
                    size="short"
                />
            </div>
            <div className="objects">
                {/* {JSON.stringify(data)} */}
                {data?.length == 0 &&
                    <bds-typo variant="fs-14" tag="h2" translate="" className="hydrated">Nenhum item</bds-typo>
                }
                {/* {Array.isArray(data) ? "array" : typeof data} */}
                {/* {JSON.stringify(data)} */}
                {/* AQUI: {JSON.stringify(data)} */}
                {(Array.isArray(data) ? (data || []) : []).map((item, indexItem) =>
                    <div className="object">
                        <div className="flutuant-bottons">
                            <Button
                                variant={"delete"}
                                icon="trash"
                                onClick={() => remove(indexItem)}
                            />
                        </div>

                        <div style={{ marginTop: 10 }}>
                            <Input
                                name="value"
                                // label={`Valor ${indexItem + 1}`}
                                value={data[indexItem]}
                                onChange={e => setValue(indexItem, e.target.value?.trim())}
                                waitTime={50}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}