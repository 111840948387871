import React, { useEffect, useState } from 'react'
import Input from '../../Input/Input'
import Select from '../../Input/Select'
import Switch from '../../Input/Switch'
import ObjectListItem from '../ObjectListItem'
import Upload from '../../Input/Upload'
import { generateUploadFileSecureURI, showToast, startLoading, stopLoading } from '../../../services/common-service'
import axios from 'axios'
import BucketFile from '../../Input/BucketFile'
import TextList from '../TextList'
import BlipPortalToastTypes from '../../../constants/blip-portal-toast-types'
import { getNodeValue, hasUndefinedVariables, replaceVariables } from '../../../services/functions'
import SelectHTTP from '../../Input/SelectHTTP'

export default function FieldItem({
    data,
    setData,
    index,
    field,
    setValue,
    isNew
}) {
    const [isLoading, setIsLoading] = useState(false)
    let [options, setOptions] = useState([])
    const [dataReference, setDataReference] = useState({})

    useEffect(() => {
        if (field?.type == "select-http")
            get()
    }, [field])

    useEffect(() => {
        if (field?.type == "select-http") {
            if (field.fields_trigger && Array.isArray(field.fields_trigger) && field.fields_trigger.length > 0) {
                for (let trigger of field.fields_trigger) {
                    if (dataReference[trigger] != data[trigger]) {
                        get()
                        setDataReference(dataReference => ({
                            ...dataReference,
                            [trigger]: data[trigger]
                        }))
                    }
                }
            }
        }
    }, [data])

    const get = () => {
        getHttpSelectOptions({
            url: field.url,
            headers: field.headers,
            path_array: field.path_array,
            attr_value: field.attr_value,
            attr_text: field.attr_text
        })
    }

    const getHttpSelectOptions = async ({
        url,
        headers,
        path_array,
        attr_value,
        attr_text
    }) => {
        try {
            // console.log("getHttpSelectOptions", { url, headers, data })
            setIsLoading(() => true)

            if (hasUndefinedVariables(url, data))
                return;

            url = replaceVariables(url, data)
            // console.log(url)

            let newHeaders = {}
            for (let header of headers)
                newHeaders[header.key] = header.value

            let { data: resultData } = await axios.get(url, { headers: newHeaders })

            let dataArray = getNodeValue(resultData, path_array)
            if (!dataArray || !Array.isArray(dataArray))
                throw { message: "Não foi possível buscar o array por HTTP" }

            let options = []
            for (let item of dataArray) {
                let text = getNodeValue(item, attr_text)
                let value = getNodeValue(item, attr_value)

                options.push({ text, value })
            }

            setOptions(options)
        } catch (error) {
            // console.error(error)
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            setIsLoading(() => false)
        }
    }

    const handleUploadChanged = async (key, files) => {
        try {
            startLoading()

            if (files.length == 0)
                throw { message: "Nenhum arquivo encontrado" }

            let uri = await generateUploadFileSecureURI(false)
            console.log("handleUploadChanged", uri)

            let file = files[0]
            let { type } = file

            let result = await axios.post(uri, file, { headers: { 'Content-Type': type } })
            // console.log(result)

            let link = result?.data?.mediaUri

            setValue(key, link)
        } catch (error) {
            // console.error("caiu aqui")
            // console.error(error)
        } finally {
            stopLoading()
        }
    }

    return (
        <div key={index}>
            {/* {JSON.stringify(data)} */}
            {/* {field.name} */}
            {field?.type == "text" &&
                <Input
                    name={field?.name}
                    label={field.label}
                    value={data[field?.name]}
                    onChange={e => setValue(field?.name, e.target.value)}
                    waitTime={50}
                />
            }

            {field?.type == "textarea" &&
                <Input
                    name={field?.name}
                    label={field.label}
                    value={data[field?.name]}
                    onChange={e => setValue(field?.name, e.target.value)}
                    waitTime={50}
                    isTextarea={true}
                    textareaRows={5}
                />
            }

            {field?.type == "select" &&
                <Select
                    label={field.label}
                    options={field.options.map(option => ({
                        text: option.name,
                        value: option.value
                    }))}
                    value={data[field?.name]}
                    onChange={e => setValue(field?.name, e.target.value)}
                />
            }

            {field?.type == "object-list" &&
                <>
                    {/* {JSON.stringify(data)} - {field.name} */}
                    <ObjectListItem
                        label={field.label}
                        field={field}
                        // data={data[field?.name] || []}
                        data={data[field.name]}
                        setData={(newData) => {
                            setData(data => {
                                // console.log("Data 1", JSON.stringify(data), field.name)
                                data[field.name] = newData(data[field.name] || [])
                                // console.log("Data 2", JSON.stringify(data))
                                return { ...data }
                            })
                        }}
                        isNew={isNew}
                    />
                </>
            }

            {field?.type == "switch" &&
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Switch
                        checked={data[field?.name] || false}
                        name={field?.name}
                        onChange={value => setValue(field?.name, value)}
                    />
                    <div style={{ marginLeft: 10 }}>
                        <bds-typo variant="fs-16" tag="h2" translate="" className="hydrated">{field.label}</bds-typo>
                        {/* - {data.active ? "true" : "false"} */}
                    </div>
                </div>
            }

            {field?.type == "file" &&
                <div>
                    <BucketFile
                        name={field?.name}
                        label={field.label}
                        value={data[field?.name]}
                        onChange={e => handleUploadChanged(field?.name, e.target.files)}
                        waitTime={50}
                    />
                </div>
            }

            {field?.type == "text-list" &&
                <div>
                    <TextList
                        label={field.label}
                        field={field}
                        data={data[field.name]}
                        setData={(newData) => {
                            setData(data => {
                                // console.log("Data 1", JSON.stringify(data), field.name)
                                data[field.name] = newData(data[field.name] || [])
                                // console.log("Data 2", JSON.stringify(data))
                                return { ...data }
                            })
                        }}
                    />
                </div>
            }

            {/* {JSON.stringify(data[field?.name])} */}
            {field?.type == "select-http" &&
                <SelectHTTP
                    label={field.label}
                    options={options}
                    placeholder={isLoading ? "Carregando..." : undefined}
                    value={
                        typeof data[field?.name] == "object" ?
                            data[field?.name]?.[field.attr_value] :
                            data[field?.name]
                    }
                    onChange={e => setValue(field?.name, e.target.value)}
                />
            }
        </div>
    )
}