import React, { createContext } from 'react'

import { useTranslation } from "react-i18next";
import Input from '../../Input/Input'

import "./styles.scss"
import Button from '../../Button'
import Select from '../../Input/Select'
import SelectField from './SelectField'
import ResourceFields from '../../ResourceFields'
import InputChips from '../../Input/InputChips'
import { AppContext, useApp } from '../../../contexts/AppContext'

export default function ResourceField({
    index,
    label,
    name,
    type,
    data,
    setData,
    newFieldButtonText,

    onDragStart,
    onDragEnd

}) {
    const { t } = useTranslation()
    const { config } = useApp()

    const setValue = (key, value) => {
        setData(data => {
            data.fields[index][key] = value

            if (value == "select" && (!data.fields[index].options || data.fields[index].options.length == 0)) {
                data.fields[index].options = [
                    {
                        name: "",
                        value: ""
                    }
                ]
            }

            if (value == "object-list" && (!data.fields[index].fields || data.fields[index].fields.length == 0)) {
                data.fields[index].fields = [
                    {
                        label: "",
                        name: "",
                        type: ""
                    }
                ]
            }

            if (value == "select-http" && (!data.fields[index].headers || data.fields[index].headers.length == 0)) {
                data.fields[index].headers = [
                    // {
                    //     key: "",
                    //     value: ""
                    // }
                ]
            }

            return { ...data }
        })
    }

    const remove = () => {
        setData(data => {
            data.fields.splice(index, 1);

            return { ...data }
        })
    }

    const handleAddOption = (attr) => {
        let defaultObj = {}

        if (attr == "options")
            defaultObj = {
                name: "",
                value: ""
            }
        else if (attr == "headers")
            defaultObj = {
                key: "",
                value: ""
            }

        setData(data => {
            data.fields[index][attr].push(defaultObj)

            return { ...data }
        })
    }

    return (
        <div className="resource-field">
            {data.fields.length > 1 &&
                <div className="flutuant-bottons">
                    <div
                        draggable
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                    >
                        <Button
                            variant={"ghost"}
                            icon="order-elements"
                            onClick={remove}
                        />
                    </div>

                    <Button
                        variant={"delete"}
                        icon="trash"
                        onClick={remove}
                    />
                </div>
            }

            <div>
                <Input
                    name="label"
                    label={t('texts.title')}
                    placeholder={t('texts.fieldTitle')}
                    value={data.fields[index].label}
                    onChange={e => setValue("label", e.target.value)}
                    waitTime={50}
                />
            </div>
            <div className="flex" style={{ gap: 10 }}>
                <div style={{ flex: 1 }}>
                    <Input
                        name="name"
                        label={t('texts.key')}
                        placeholder={t('texts.fieldKey')}
                        value={data.fields[index].name}
                        onChange={e => setValue("name", e.target.value)}
                        waitTime={50}
                    />
                </div>
                <div style={{ flex: 1 }}>
                    <Select
                        label={t('texts.type')}
                        options={[
                            { text: t('texts.text'), value: "text" },
                            { text: t('texts.textarea'), value: "textarea" },
                            { text: t('texts.select'), value: "select" },
                            { text: t('texts.textList'), value: "text-list" },
                            { text: t('texts.objectList'), value: "object-list" },
                            { text: t('texts.onOff'), value: "switch" },
                            { text: t('texts.file'), value: "file" },
                            // { text: t('texts.selectHTTP'), value: "select-http" },
                        ].filter(({ value }) => config.fieldsTypesAvailable.includes(value))}
                        value={data.fields[index].type}
                        onChange={e => setValue("type", e.target.value)}
                    />
                </div>
            </div>

            {data.fields[index].type == "select" &&
                <div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <bds-typo variant="fs-16" tag="h2" translate="" className="hydrated">{t('texts.options')}</bds-typo>

                        <Button
                            variant={"primary"}
                            text={t('button.newOption')}
                            onClick={() => handleAddOption("options")}
                            size="short"
                        />
                    </div>

                    <div className="mt2 options">
                        {/* {JSON.stringify(data.fields[index]?.options)} */}
                        {data.fields[index]?.options?.map((option, indexOption) =>
                            <SelectField
                                key={`indexOption-${indexOption}`}
                                data={data}
                                setData={setData}
                                indexField={index}
                                indexOption={indexOption}
                                totalSize={data.fields[index]?.options.length}
                            />
                        )}
                    </div>
                </div>
            }

            {data.fields[index].type == "object-list" &&
                <div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <bds-typo variant="fs-16" tag="h2" translate="" className="hydrated">{t('texts.objectFields')}</bds-typo>

                        <Button
                            variant={"primary"}
                            text={newFieldButtonText || t('button.newField')}
                            onClick={() => {
                                setData(data => {
                                    data.fields[index].fields.push([
                                        {
                                            name: "",
                                            value: ""
                                        }
                                    ])

                                    return { ...data }
                                })
                            }}
                            size="short"
                        />
                    </div>

                    <div className="mt2 options">
                        {/* {JSON.stringify(data.fields[index])} */}
                        <ResourceFields
                            data={data.fields[index]}
                            setData={data2 => {
                                // console.log(data2)
                                setData(data => {
                                    let data2Value = data2({ ...data.fields[index] })

                                    data.fields[index] = { ...data2Value }

                                    for (let i = 0; i < data.fields[index].fields.length; i++) {
                                        data.fields[index].fields[i] = {
                                            ...data.fields[index].fields[i]
                                        }
                                    }

                                    return JSON.parse(JSON.stringify(data))
                                })
                            }}
                            newFieldButtonText={t('button.newFieldObject')}
                        />
                    </div>
                </div>
            }


            {data.fields[index].type == "select-http" &&
                <div style={{ display: 'flex', flexDirection: "column", gap: 10 }}>
                    <div className="flex" style={{ gap: 10 }}>
                        <div style={{ flex: 1 }}>
                            <Input
                                name="url"
                                label="URL"
                                placeholder="URL"
                                value={data.fields[index].url}
                                onChange={e => setValue("url", e.target.value)}
                                waitTime={50}
                            />
                        </div>
                    </div>
                    <div className="flex" style={{ gap: 10 }}>
                        <div style={{ flex: 1 }}>
                            <Input
                                name="path_array"
                                label="Caminho da lista"
                                placeholder="Caminho da lista de opções"
                                value={data.fields[index].path_array}
                                onChange={e => setValue("path_array", e.target.value)}
                                waitTime={50}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Input
                                name="attr_text"
                                label="Caminho do texto da opção"
                                placeholder="Caminho do texto da opção"
                                value={data.fields[index].attr_text}
                                onChange={e => setValue("attr_text", e.target.value)}
                                waitTime={50}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Input
                                name="attr_value"
                                label="Caminho do valor da opção"
                                placeholder="Caminho do valor da opção"
                                value={data.fields[index].attr_value}
                                onChange={e => setValue("attr_value", e.target.value)}
                                waitTime={50}
                            />
                        </div>
                    </div>
                    <div>
                        <InputChips
                            label="Atualizar ao alterar"
                            placeholder="Este campo é opcional"
                            value={data.fields[index].fields_trigger}
                            onChange={({ data }) => setValue("fields_trigger", data)}
                        />
                    </div>
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <bds-typo variant="fs-16" tag="h2" translate="" className="hydrated">Cabeçalhos</bds-typo>

                            <Button
                                variant={"primary"}
                                text="Adicionar ao cabeçalho"
                                onClick={() => handleAddOption("headers")}
                                size="short"
                            />
                        </div>

                        <div className="mt2 options">
                            {/* {JSON.stringify(data.fields[index]?.options)} */}
                            {data.fields[index]?.headers?.map((option, indexOption) =>
                                <SelectField
                                    key={`indexOption-${indexOption}`}
                                    data={data}
                                    setData={setData}
                                    indexField={index}
                                    indexOption={indexOption}
                                    totalSize={data.fields[index]?.headers.length}

                                    arrayAttr="headers"

                                    firstElementName="key"
                                    firstElementText="Chave"
                                    firstElementPlaceholder="Chave do cabeçalho"

                                    referenceSizeToDelete={0}
                                />
                            )}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}