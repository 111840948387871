import React, { useContext, useEffect, useState } from 'react'
import Input from '../Input/Input'
import Select from '../Input/Select'
import Button from '../Button'

import axios from "axios"

import "./styles.scss"
import { showToast, startLoading, stopLoading } from '../../services/common-service'
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types'

import { v4 as uuid } from 'uuid';
import ObjectListItem from './ObjectListItem'
import FieldItem from './FieldItem'
import Modal from '../Modal'
import { AppContext } from '../../contexts/AppContext'
import { useBlip } from '../../contexts/BlipContext'
import { replaceVariables } from '../../services/functions'

export default function ModalItem({
    opened,
    setOpened,
    resource,
    setItems,
    items
}) {
    const { t } = useContext(AppContext)

    const { getResource, setResource } = useBlip()

    const [isNew, setIsNew] = useState(true)
    const [data, setData] = useState({
        name: "",
        key: "",
        fields: [
            {
                label: "",
                name: "",
                type: ""
            }
        ]
    })

    const adjustData = (data, fields) => {
        for (let field of fields) {
            if (field.fields) {
                for (let [i, item] of (data[field.name]?.entries() || [])) {
                    data[field.name][i] = adjustData(data[field.name][i], field.fields)
                    console.log(data[field.name], i, adjustData(data[field.name][i], field.fields))
                }
            } else if (["select-http", "select"].includes(field.type)) {
                if (data.hasOwnProperty(field.name) && typeof data[field.name] == "object") {
                    data[field.name] = data[field.name]?.[field.attr_value]
                }
            }
        }

        return { ...data }
    }

    useEffect(() => {
        if (opened) {
            if (Object.keys(opened).length == 0) {
                let newData = {}
                for (let field of resource.fields) {
                    if (field.type == "switch") {
                        newData[field?.name] = true
                    } else
                        newData[field?.name] = ""
                }

                setData({
                    _id: uuid(),
                    ...newData
                })
                setIsNew(true)
            } else {
                // console.log(resource)
                // console.log(opened)
                let newData = adjustData({ ...opened }, resource.fields)

                setData(JSON.parse(JSON.stringify(newData)))
                setIsNew(false)
            }
        } else {
            setData({})
        }
    }, [opened])

    const close = () => {
        setOpened(() => null)
    }

    const submit = async () => {
        try {
            startLoading()
            // console.log(data)
            // return

            // console.log("submit", {
            //     resource
            // })

            // let items = resource?.type == "collection" ? [] : {}

            let newItems;

            if (resource.source == "http") {
                let { httpSource } = resource
                let { primaryKey, post, put, baseUrl, headers } = httpSource || {}

                // console.log({ httpSource })

                let isNew = Object.keys(opened).length == 0

                let { method, path, body, dataPath } = (isNew ? post : put) || {}

                if (baseUrl[baseUrl.length - 1] == "/")
                    baseUrl = baseUrl.slice(0, -1)

                if (path?.[0] == "/")
                    path = path.slice(1)

                method = method?.toLowerCase()

                // console.log({ opened, isNew, headers, path, body, data })

                headers = replaceVariables(headers || {}, data)
                path = replaceVariables(path, data)
                body = replaceVariables(body, data)

                // console.log({ headers, path, body, data })

                let url = `${baseUrl}/${path}`

                let result = await axios({
                    method,
                    url,
                    data: body,
                    headers
                })

                if (dataPath && result?.data?.[dataPath]) {
                    let newItem = result?.data?.[dataPath]

                    setItems((items) => {
                        let found = false

                        if (!isNew) {
                            for (let [index, item] of (items?.entries() || [])) {
                                if (item[primaryKey] == newItem[primaryKey]) {
                                    items[index] = newItem
                                    found = true
                                    break
                                }
                            }
                        }

                        if (!found)
                            return [...items, newItem]
                        return [...items]
                    })
                }
                close()
            } else {
                if (!resource?.source || resource?.source == "resource") {
                    let items;

                    try {
                        const result = await getResource(resource.key, resource?.type == "collection" ? "items" : undefined)
                        // console.log("reuslt", result)

                        items = resource?.type == "collection" ? result?.items : result
                    } catch (error) {
                        if (typeof error == "string") {
                            error = JSON.parse(error)
                            if (error?.message && typeof error.message == "string")
                                error.message = JSON.parse(error.message)
                        }

                        if (error?.message?.reason?.code == 67) {
                            items = resource?.type == "collection" ? [] : {}
                        } else
                            throw error
                    }

                    newItems = resource?.type == "collection" ? [...items] : { ...items }
                } else
                    newItems = resource?.type == "collection" ? [...items] : { ...items }

                if (resource?.type == "collection") {
                    let itemsIndex = newItems.findIndex(item => item._id == data._id)

                    if (itemsIndex >= 0) {
                        newItems[itemsIndex] = data
                    } else {
                        newItems.push(data)
                    }
                } else {
                    newItems = {
                        ...newItems,
                        ...data
                    }
                }

                // console.log({
                //     data,
                //     items,
                //     newItems
                // })

                if (resource.source == "resource")
                    await setResource(resource.key, resource?.type == "collection" ? { items: newItems } : newItems)

                setItems(newItems)
            }

            close()
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
        }
    }

    const setValue = (key, value) => {
        setData(data => {
            data[key] = value
            return { ...data }
        })
    }

    return (
        <Modal open={opened != null} close={close} close-button="false" size="dynamic">
            <div className="modal-resources">
                {/* {JSON.stringify(opened)} */}
                <div className="modal-body">
                    {
                        (["collection", "eventTrack"].includes(resource?.type) ?
                            resource?.fields :
                            Object.keys(data).length > 0 ?
                                [resource?.fields?.find(field => field?.name == Object.keys(data)?.[0])] :
                                []
                        )
                            .map((field, index) => (
                                (field && (
                                    field?.insertable != false && field?.editable != false) ||
                                    (isNew && field?.insertable != false) ||
                                    (!isNew && field?.editable != false)
                                ) ?
                                    <FieldItem
                                        key={index}
                                        data={data}
                                        setData={setData}
                                        index={index}
                                        field={field}
                                        setValue={setValue}
                                        isNew={isNew}
                                    />
                                    :
                                    <></>
                            ))}
                </div>

                {/* {JSON.stringify(data)} */}

                {/* <bds-modal-action> */}
                <div className="flex justify-end" style={{ width: "100%", zIndex: 0 }}>
                    <bds-button variant="secondary" onClick={close}>{t('button.cancel')}</bds-button>
                    {["collection", "single"].includes(resource?.type) && <bds-button onClick={submit}>{t('button.done')}</bds-button>}
                </div>
                {/* </bds-modal-action> */}
            </div>
        </Modal>
    )
}