import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';

import "./styles.scss"
import Select from './Select';

const SelectHTTP = ({
    ...rest
}) => {
    return (
        <div>
            <Select
                {...rest}
            />
        </div>
    );
};

SelectHTTP.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    icon: PropTypes.string,
    value: PropTypes.string,
    danger: PropTypes.bool,
    focused: PropTypes.bool,
    waitTime: PropTypes.number,
    errors: PropTypes.object,
    touched: PropTypes.object,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    options: PropTypes.array,
};

export default SelectHTTP;
