import React, { useContext, useEffect } from 'react';
import Button from '../../Button';
import FieldItem from '../FieldItem';

import "./styles.scss"
import { AppContext } from '../../../contexts/AppContext';

export default function ObjectListItem({
    label,
    data = [],
    setData,
    field,
    isNew
}) {
    const { t } = useContext(AppContext)

    const handleAddOption = () => {
        setData(data => {
            data = JSON.parse(JSON.stringify(data))

            // console.log("DATA ANTES DO ADD", data);
            // console.log(JSON.stringify(data))
            // console.log("field", JSON.stringify(field))
            let newObj = {}

            field.fields.map(field => {
                if (field.type == "object-list")
                    newObj[field.name] = []
                else
                    newObj[field.name] = ""
            })

            data = [
                ...data,
                newObj
            ]
            // console.log("DATA DEPOIS DO ADD", data);
            // console.log(JSON.stringify(data))

            return JSON.parse(JSON.stringify(data))
            // return [...data]
        })
    }

    const setValue = (key, value, index) => {
        // console.log("setValue", key, value, index)
        setData(data => {
            data[index][key] = value
            return [...data]
        })
    }

    const remove = (index) => {
        setData(data => {
            data.splice(index, 1)

            return [...data]
        })
    }

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <bds-typo variant="fs-16" tag="h2" translate="" className="hydrated">{label}</bds-typo>

                <Button
                    variant={"primary"}
                    text={t('texts.add')}
                    onClick={handleAddOption}
                    size="short"
                />
            </div>
            <div className="objects">
                {/* {JSON.stringify(data)} */}
                {data?.length == 0 &&
                    <bds-typo variant="fs-14" tag="h2" translate="" className="hydrated">Nenhum item</bds-typo>
                }
                {/* {Array.isArray(data) ? "array" : typeof data} */}
                {/* {JSON.stringify(data)} */}
                {/* AQUI: {JSON.stringify(data)} */}
                {(Array.isArray(data) ? data : [])?.map((item, indexItem) =>
                    <div className="object">
                        <div className="flutuant-bottons">
                            <Button
                                variant={"delete"}
                                icon="trash"
                                onClick={() => remove(indexItem)}
                            />
                        </div>

                        <div className="fields">
                            {/* {JSON.stringify(data)} */}
                            {field.fields
                                .map((field, index) =>
                                    (field && (
                                        field?.insertable != false && field?.editable != false) ||
                                        (isNew && field?.insertable != false) ||
                                        (!isNew && field?.editable != false)
                                    ) ?
                                        <div className="field">
                                            {/* {JSON.stringify(field)} */}
                                            <FieldItem
                                                data={item}
                                                index={index}
                                                field={field}
                                                setValue={(key, value) => setValue(key, value, indexItem)}
                                                setData={(data2) => {
                                                    setData(data => {
                                                        data = JSON.parse(JSON.stringify(data))
                                                        // console.log("DATA", JSON.stringify(data))
                                                        let data2Value = data2(data[indexItem])
                                                        // console.log("DATA 2 VALUE", data2Value)
                                                        // console.log(JSON.stringify(data2Value))
                                                        // data[index][field.name] = 

                                                        // console.log("NEW DATA", data)

                                                        return [...data]
                                                    })
                                                    // console.log("data", JSON.stringify(data))
                                                    // console.log("data2", JSON.stringify(data2))
                                                }}
                                            />
                                        </div>
                                        : <></>
                                )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}