import React, { useContext, useEffect, useState } from 'react'
import Input from '../Input/Input'
import Button from '../Button'

import "./styles.scss"
import { showToast, startLoading, stopLoading } from '../../services/common-service'
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types'

import { IframeMessageProxy } from 'iframe-message-proxy'
import IMPContainer from '../../constants/iframe-message-proxy-container';

import { v4 as uuid } from 'uuid';
import Select from '../Input/Select'
import ResourceFields from '../ResourceFields'
import Modal from '../Modal'
import { AppContext, useApp } from '../../contexts/AppContext'
import { useBlip } from '../../contexts/BlipContext'
import FieldItem from '../ModalItem/FieldItem'

export default function ModalResource({
    opened,
    setOpened,
    start,
}) {
    const { t } = useContext(AppContext)

    const { getResource, setResource, getEventCategories } = useBlip()
    const { config } = useApp()

    const [eventCategories, setEventCategories] = useState([])

    const [isNew, setIsNew] = useState(true)
    const [data, setData] = useState({
        name: "",
        key: "",
        type: config.typeResourceDefault,
        fields: [
            {
                label: "",
                name: "",
                type: ""
            }
        ]
    })

    useEffect(() => {
        if (opened) {
            if (Object.keys(opened).length == 0) {
                setData({
                    _id: uuid(),
                    name: "",
                    key: "",
                    type: config.typeResourceDefault,
                    fields: [
                        {
                            label: "",
                            name: "",
                            type: ""
                        }
                    ]
                })
                setIsNew(true)
            } else {
                setData({ ...opened })
                setIsNew(false)
            }
        }
    }, [opened])

    useEffect(async () => {
        if (data.type == "eventTrack") {
            let eventCategories = await getEventCategories()
            setEventCategories(eventCategories)
        }
    }, [data.type])

    const close = () => {
        setOpened(() => null)
    }

    const submit = async () => {
        try {
            startLoading()

            let resourcesManagerPlugin = []

            try {
                const result = await getResource(config.resourceName, "resources")

                resourcesManagerPlugin = result.resources || []
            } catch (error) {
                throw error
            }

            let newResourcesManagerPlugin = resourcesManagerPlugin

            // console.log(newResourcesManagerPlugin)

            let resourcesManagerPluginIndex = newResourcesManagerPlugin.findIndex(resource => resource._id == data._id)

            if (resourcesManagerPluginIndex >= 0) {
                newResourcesManagerPlugin[resourcesManagerPluginIndex] = data
            } else {
                newResourcesManagerPlugin.push(data)
            }

            await setResource(config.resourceName, { resources: newResourcesManagerPlugin })

            start()
            close()
        } catch (error) {
            // console.log(JSON.parse(error))
            // console.error(error)
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
        }
    }

    const setValue = (key, value) => {
        setData(data => {
            data[key] = value
            return { ...data }
        })
    }

    const handleAddField = () => {
        setData(data => {
            data.fields.push({
                label: "",
                name: "",
                type: ""
            })

            return { ...data }
        })
    }

    if (!opened)
        return <></>

    return (
        <Modal open={opened != null} close={close} close-button="false" size="dynamic">
            <div className="modal-resources">
                {/* {JSON.stringify(data)} */}
                <div className="modal-body">
                    <bds-typo variant="fs-16" tag="h2" translate="" className="hydrated">{t('texts.basicInformation')}</bds-typo>
                    <div style={{ display: "flex", gap: 10 }}>
                        <div style={{ flex: 1 }}>
                            <Input
                                name="name"
                                label={t('name')}
                                placeholder={t('texts.placeholderName')}
                                value={data.name}
                                onChange={e => setValue("name", e.target.value?.trim())}
                                waitTime={50}
                                helperMessage={t('texts.aboutFieldName')}
                            />
                        </div>
                        {!["swagger"].includes(data.type) &&
                            <div style={{ flex: 1 }}>
                                <Input
                                    name="key"
                                    label={t('texts.key')}
                                    placeholder={t('texts.placeholderKey')}
                                    value={data.key}
                                    onChange={e => setValue("key", e.target.value?.trim())}
                                    waitTime={50}
                                    helperMessage={t('texts.aboutFieldKey')}
                                />
                            </div>
                        }
                    </div>

                    {config.showTypeResourceSelector &&
                        <Select
                            label={t('texts.resourceType')}
                            options={[
                                { text: t('texts.collection'), value: "collection" },
                                { text: t('texts.single'), value: "single" },
                                // { text: t('texts.eventTrack'), value: "eventTrack" },
                                { text: "Swagger", value: "swagger" },
                            ].filter(({ value }) => config.resourcesTypesAvailable.includes(value))}
                            value={data.type}
                            onChange={e => setValue("type", e.target.value?.trim())}
                            placeholder={t('texts.placeholderResourceType')}
                        />
                    }

                    {data.type == "eventTrack" &&
                        <Select
                            label={t('texts.eventCategory')}
                            options={eventCategories.map((eventCategory) => ({
                                text: eventCategory.category, value: eventCategory.category
                            }))}
                            value={data.eventCategory}
                            onChange={e => setValue("eventCategory", e.target.value?.trim())}
                            placeholder={t('texts.placeholderResourceType')}
                        />
                    }

                    {!["swagger"].includes(data.type) &&
                        <>
                            <bds-typo variant="fs-16" tag="h2" translate="" className="hydrated">{t('texts.fields')}</bds-typo>
                            <ResourceFields
                                data={data}
                                setData={setData}
                            />

                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end" }}>
                                <Button
                                    variant={"primary"}
                                    text={t('button.newField')}
                                    onClick={handleAddField}
                                    size="short"
                                />
                            </div>
                        </>
                    }

                    {["swagger"].includes(data.type) &&
                        <FieldItem
                            data={data}
                            setData={setData}
                            index={0}
                            field={{
                                type: "file",
                                name: "file",
                                label: "Swagger JSON"
                            }}
                            setValue={(key, value) => {
                                setData(data => {
                                    data[key] = value
                                    return { ...data }
                                })
                            }}
                        // isNew={isNew}
                        />
                    }
                </div>
                {/* 
                <div style={{ maxWidth: 800 }}>
                    {JSON.stringify(data)}
                </div> */}

                <bds-modal-action>
                    <div className="flex justify-end" style={{ width: "100%" }}>
                        <bds-button variant="secondary" onClick={close}>{t('button.cancel')}</bds-button>
                        <bds-button
                            onClick={submit}
                            disabled={!(
                                (data.name && data.name.trim().length > 0) &&
                                (!["swagger"].includes(data.type) && data.key && data.key.trim().length > 0) &&
                                (data.type && data.type.trim().length > 0) &&
                                (!["swagger"].includes(data.type) && data.fields && data.fields.length > 0)
                            )}
                        >{t('button.done')}</bds-button>
                    </div>
                </bds-modal-action>
            </div>
        </Modal>
    )
}