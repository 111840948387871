import React, { useContext, useRef, useState } from "react";

import "./styles.scss"
import ResourceField from "../ModalResource/ResourceField";
import { AppContext } from "../../contexts/AppContext";


export default function ResourceFields({
    data,
    setData,
    newFieldButtonText
}) {
    const { t } = useContext(AppContext)

    const dragItem = useRef();
    const dragOverItem = useRef();

    const [dragEnabled, setDragEnabled] = useState(null)
    const [dragPosition, setDragPosition] = useState(null)

    const dragStart = (e, position) => {
        // console.log("dragStart", e, position)
        dragItem.current = position;
        // console.log(e.target.innerHTML);

        setDragEnabled(() => position)
    };

    const dragEnter = (e, position) => {
        // console.log("dragEnter", e, position)
        dragOverItem.current = position;
        // console.log(e.target.innerHTML);

        setDragPosition(() => position)
    };

    const drop = (e) => {
        // console.log("drop", dragItem.current, dragOverItem.current)
        const copyListItems = [...data.fields];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        // console.log(copyListItems)
        setData(data => {
            data.fields = copyListItems
            return { ...data }
        })


        setDragEnabled(() => null)
        setDragPosition(() => null)
    };

    const DragArea = () => (
        <div className="drag-area">
            <bds-typo variant="fs-16" tag="h2" translate="" className="hydrated">Soltar aqui</bds-typo>
        </div>
    )

    return (
        <div
            id="teste"
            className="fields"
        >
            {data.fields.map((field, index) =>
                <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    {(dragEnabled != null && dragEnabled >= 0 && dragPosition == index && index < dragEnabled) && <DragArea />}
                    <div
                        // onDragStart={(e) => dragStart(e, index)}
                        // onDragEnter={(e) => dragEnter(e, index)}
                        // onDragEnd={drop}
                        // draggable
                        onDragEnter={(e) => dragEnter(e, index)}
                    >
                        <ResourceField
                            index={index}
                            data={data}
                            setData={(data) => setData(data)}
                            newFieldButtonText={newFieldButtonText}

                            onDragStart={(e) => dragStart(e, index)}
                            onDragEnd={drop}

                            {...field}
                        />
                    </div>
                    {(dragEnabled != null && dragEnabled >= 0 && dragPosition == index && index > dragEnabled) && <DragArea />}
                </div>
            )}
        </div>
    )
}