import React, { useRef } from 'react'
import Input from './Input';
import Button from '../Button';

import "./styles.scss"

export default function BucketFile({
    name,
    label,
    value,
    onChange
}) {
    const ref = useRef()

    return (
        <div className="bucketFile">
            <div>
                <Input
                    name={name}
                    label={label}
                    value={value}
                    onChange={() => { }}
                    disabled={true}
                />
            </div>

            <div>
                <div>
                    <Button
                        text={<bds-icon name="upload" size="small" class="flex-shrink-0 bds-icon bds-icon__size--small hydrated" role="img" aria-label="upload" theme="outline"></bds-icon>}
                        onClick={() => ref?.current?.click()}
                    />
                </div>

                <input
                    type="file"
                    accept=".json"
                    style={{ display: 'none' }}
                    onChange={onChange}
                    ref={ref}
                />
            </div>
        </div>
    )
}