export const generateAuthorizationToken = ({ accessKey, shortName }) => {
    try {
        return `Key ${btoa(`${shortName}:${atob(accessKey)}`)}`
    } catch (error) {
        // console.error(error)
        return ""
    }
}

export const getValueByPath = (object, path) => {
    if (!object)
        return null

    const keys = path.split('.');
    let value = object;

    for (const key of keys) {
        if (value && typeof value === 'object' && key in value) {
            value = value[key];
        } else {
            return null;
        }
    }

    return value;
}

export const replaceVariables = (template, variables) => {
    let type = typeof template

    if (type == "object")
        template = JSON.stringify(type)

    const regex = /{{(.*?)}}/g;

    const replacedString = template.replace(regex, (match, variable) => {
        const trimmedVariable = variable.trim();
        return variables.hasOwnProperty(trimmedVariable) ? variables[trimmedVariable] : match;
    });

    let result = type == "object" ? JSON.parse(replacedString) : replacedString

    return result
}
