import React, { useContext } from 'react'

import { AppContext } from '../../contexts/AppContext'
import { useSharkPlugins } from '../../contexts/SharkPluginsProvider'

export default function Copyright() {
    const { t } = useContext(AppContext)

    const { sendAction } = useSharkPlugins()

    return (
        <div className="center flex justify-center mt3" style={{ gap: 10 }}>
            <p>{t('developedBy')}</p>

            <a href="https://sharkdev.com.br" target="_blank" onClick={() => {
                sendAction({
                    type: "event",
                    action: "clickLogoShark",
                    value: 1
                })
            }}>
                <img src="https://sharkdev.com.br/static/logos/logo_dark.svg" />
            </a>
        </div>
    )
}