import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { v4 as uuid } from 'uuid';

const DatePicker = ({
    name,
    focused = false,
    waitTime = 500,
    errors = {},
    touched = {},
    onFocus,
    onBlur,
    onChange,
    startDateLimit,
    endDateLimit
}) => {
    const blipInputRef = useRef(null);
    const [error, setError] = useState('');
    let time = null;

    useEffect(() => {
        const { current } = blipInputRef;
        // console.log("blipInputRef", blipInputRef)
        current.addEventListener('bdsStartDate', (e) => handleChange({ startDate: e.detail.value }));
        current.addEventListener('bdsEndDate', (e) => handleChange({ endDate: e.detail.value }));
        // current.addEventListener('bdsFocus', onFocus);
        // current.addEventListener('bdsOnBlur', onBlur);

        return () => {
            current.removeEventListener('bdsStartDate', (e) => handleChange({ startDate: e.detail.value }));
            current.removeEventListener('bdsEndDate', (e) => handleChange({ endDate: e.detail.value }));
            //     current.removeEventListener('bdsFocus', onFocus);
            //     current.removeEventListener('bdsOnBlur', onBlur);
        };
        // eslint-disable-next-line
    }, []);

    /*useEffect(() => {
        const { current } = blipInputRef;
        if (!!focused && !!current) {
            current.setFocus();
        }
    }, [focused]);

    useEffect(() => {
        setError(
            getIn(touched, name) && getIn(errors, name)
                ? getIn(errors, name)
                : ''
        );
    }, [touched, errors, name]);*/

    const handleChange = (e) => {
        // console.log("handleChange", e)
        // if (e.srcElement.id == id) {
        clearTimeout(time);
        time = setTimeout(() => onChange(e), waitTime);
        // }
    };

    return (
        <div className="relative">
            <bds-datepicker
                ref={blipInputRef}
                type-of-date='period'
                start-date-limit='31/12/2022'
                end-date-limit='01/01/2027'
            />
        </div>
    );
};

export default DatePicker;
