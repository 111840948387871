import React, { useContext, useEffect, useState } from 'react'

import "./styles.scss"
import Button from '../Button'
import ModalResource from '../ModalResource'
import { IframeMessageProxy } from 'iframe-message-proxy'
import IMPContainer from '../../constants/iframe-message-proxy-container';
import Card from '../Card/Card'
import ModalItem from '../ModalItem'
import { showToast, startLoading, stopLoading } from '../../services/common-service'
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types'
import Alert from '../Alert'
import ModalImportItems from '../ModalImportItems'
import CopyToClipboard from 'react-copy-to-clipboard'
import { AppContext, useApp } from '../../contexts/AppContext'
import { useBlip } from '../../contexts/BlipContext'
import axios from 'axios'
import { getValueByPath } from '../../services/utils'
import { replaceVariables } from '../../services/functions'
import DatePicker from '../Input/DatePicker'
import moment from "moment"

export default function Resource({
    resource,
    setResourceOpened,
}) {
    const { t, config } = useApp()

    const { getResource, setResource, getEventDetails } = useBlip()

    const [items, setItems] = useState([])
    const [isModalItemOpened, setIsModalItemOpened] = useState(null)
    const [isModalImportOpened, setIsModalImportOpened] = useState(null)

    const [alertOpened, setAlertOpened] = useState(false)
    const [alert, setAlert] = useState({})

    const [dateStart, setDateStart] = useState(null)
    const [dateEnd, setDateEnd] = useState(null)

    let primaryKey = resource?.source == "http" ? resource?.httpSource?.primaryKey : "_id"

    useEffect(() => {
        if (resource)
            start()
    }, [resource])

    useEffect(() => {
        if (dateStart && dateEnd) {
            start()
        }
    }, [dateStart, dateEnd])

    const getItemsFromResource = async () => {
        try {
            const result = await getResource(resource.key, "items")
            // console.log(result)

            return resource?.type == "collection" ?
                (
                    Array.isArray(result.items) ?
                        result.items :
                        (result.items ? [result.items] : [])
                ) :
                result
        } catch (error) {
            if (typeof error == "string") {
                error = JSON.parse(error)
                if (error?.message && typeof error.message == "string")
                    error.message = JSON.parse(error.message)
            }

            // console.log("error", error)

            if (error?.message?.reason?.code == 67)
                return resource?.type == "collection" ?
                    [] :
                    {}

            throw error
        }
    }

    const getItemsFromHTTP = async () => {
        let { httpSource } = resource
        let { baseUrl, headers, list } = httpSource

        if (baseUrl[baseUrl.length - 1] == "/")
            baseUrl = baseUrl.slice(0, -1)

        let { path, method = "get", dataPath } = list || {}

        if (path?.[0] == "/")
            path = path.slice(1)

        let url = `${baseUrl}/${path}`

        method = method?.toLowerCase()
        let result = await axios({
            method,
            url,
            headers
        })

        let { data } = result || {}

        let content = getValueByPath(data || {}, dataPath)

        return content || []
    }

    const getItemsFromEventTrack = async () => {
        let category = resource.eventCategory

        if (!dateStart || !dateEnd)
            return []

        // console.log({ dateStart, dateEnd })

        if (category) {
            let events = await getEventDetails(category, {
                take: 10000,
                startDate: moment(dateStart).format("YYYY-MM-DD"),
                endDate: moment(dateEnd).format("YYYY-MM-DD")
            })

            events = events.map(({ action }) => JSON.parse(action))

            return events
        }

        return []
    }

    const getItems = async () => {
        if (resource.type == "eventTrack") {
            return await getItemsFromEventTrack()
        } else {
            resource.source = resource.source || "resource"

            if (resource.source == "resource")
                return await getItemsFromResource()
            if (resource.source == "http")
                return await getItemsFromHTTP()
        }
    }

    const start = async () => {
        try {
            // console.log("start")
            startLoading()
            let resources = await getItems()
            // console.log(resources)

            setItems(resources)
        } catch (error) {
            // console.error(error)
        } finally {
            stopLoading()
        }
    }

    const removeFromResource = async (_id) => {
        let items = []

        try {
            const result = await getResource(resource.key, "items")

            items = result?.items
        } catch (error) {
            if (typeof error == "string") {
                error = JSON.parse(error)
                if (error?.message && typeof error.message == "string")
                    error.message = JSON.parse(error.message)
            }

            // console.log("error", error)

            if (error?.message?.reason?.code == 67) {
                items = []
            } else
                throw error
        }

        let newItems = items.filter(item => item._id != _id)

        await setResource(resource.key, { items: newItems })

        return newItems
    }

    const removeFromHTTP = async (_id) => {
        try {
            let { httpSource } = resource
            let { delete: del, baseUrl, headers } = httpSource || {}

            // console.log(httpSource)

            let { method, path, body } = del || {}

            if (baseUrl[baseUrl.length - 1] == "/")
                baseUrl = baseUrl.slice(0, -1)

            if (path?.[0] == "/")
                path = path.slice(1)

            method = method?.toLowerCase()

            let data = items.find((item) => item[primaryKey] == _id)
            // console.log(data)

            if (data) {
                headers = replaceVariables(headers || {}, data)
                path = replaceVariables(path, data)
                body = replaceVariables(body || {}, data)

                let url = `${baseUrl}/${path}`

                let result = await axios({
                    method,
                    url,
                    data: body,
                    headers
                })

                let newItems = ((items) => {
                    for (let [index, item] of (items?.entries() || [])) {
                        if (item[primaryKey] == _id) {
                            items.splice(index, 1)
                            break
                        }
                    }
                    return [...items]
                })(items)

                return newItems
            } else
                return items
        } catch (error) {
            // console.error(error)
        }
    }

    const remove = async (_id) => {
        try {
            startLoading()
            setAlertOpened(false)

            if (resource?.source == "http") {
                let newItems = await removeFromHTTP(_id)
                setItems(newItems)
            } else {
                let newItems = await removeFromResource(_id)
                setItems(newItems)
            }
        } catch (error) {
            // console.log(JSON.parse(error))
            // console.error(error)
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
            setAlertOpened(false)
        }
    }

    const download = (name, data) => {
        try {
            // create file in browser
            const fileName = `${name.split(" ").join("_")}`;
            const json = JSON.stringify(data, null, 2);
            const blob = new Blob([json], { type: "application/json" });
            const href = URL.createObjectURL(blob);

            // create "a" HTLM element with href to file
            const link = document.createElement("a");
            link.href = href;
            link.download = fileName + ".json";
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } finally {
            setAlertOpened(false)
        }
    }

    const ShowData = ({
        field,
        data,
        empty = <></>
    }) =>
        <>
            {({
                "empty": empty,
                "object-list":
                    <bds-typo variant="fs-16"><i>{data?.length} {t(data?.length != 1 ? 'texts.itemPlural' : 'texts.itemSingular')}</i></bds-typo>,
                "switch":
                    <bds-typo variant="fs-16">{data ? "Sim" : "Não"}</bds-typo>,
                "file":
                    <div className="typeFileTd" style={{ cursor: "pointer" }}>
                        <CopyToClipboard
                            text={data}
                            onCopy={() =>
                                showToast({
                                    type: BlipPortalToastTypes.SUCCESS,
                                    title: t('messages.copiedToClipboard'),
                                    message: t('messages.successfullyCopied')
                                })
                            }
                        >
                            <bds-typo variant="fs-16">{data}</bds-typo>
                        </CopyToClipboard>
                    </div>,
                "text-list":
                    <div>
                        <ul style={{ margin: 0, padding: 0, paddingLeft: 15 }}>
                            {(Array.isArray(data) ? data : data?.items)?.map((text, index) =>
                                <li key={index} style={{ marginLeft: 0, paddingLeft: 0, padding: 0 }}>
                                    <bds-typo variant="fs-16">{text}</bds-typo>
                                </li>
                            )}
                        </ul>
                    </div>,
                "select-http":
                    <bds-typo variant="fs-16">{typeof data == "object" ? JSON.stringify(data) : data}</bds-typo>
            })[data == undefined ? "empty" : field.type] ||
                <bds-typo variant="fs-16">{data}</bds-typo>
            }
            {/* {data == undefined ? empty :
                field.type == "object-list" ?
                    <bds-typo variant="fs-16"><i>{data.length} {t(data.length > 1 ? 'texts.itemPlural' : 'texts.itemSingular')}</i></bds-typo> :
                    field.type == "switch" ?
                        <bds-typo variant="fs-16">{data ? "Sim" : "Não"}</bds-typo> :
                        field.type == "file" ?
                            <div className="typeFileTd" style={{ cursor: "pointer" }}>
                                <CopyToClipboard
                                    text={data}
                                    onCopy={() =>
                                        showToast({
                                            type: BlipPortalToastTypes.SUCCESS,
                                            title: t('messages.copiedToClipboard'),
                                            message: t('messages.successfullyCopied')
                                        })
                                    }
                                >
                                    <bds-typo variant="fs-16">{data}</bds-typo>
                                </CopyToClipboard>
                            </div> :
                            field.type == "text-list" ?
                                <div>
                                    <ul style={{ margin: 0, padding: 0, paddingLeft: 15 }}>
                                        {(Array.isArray(data) ? data : data?.items)?.map((text, index) =>
                                            <li key={index} style={{ marginLeft: 0, paddingLeft: 0, padding: 0 }}>
                                                <bds-typo variant="fs-16">{text}</bds-typo>
                                            </li>
                                        )}
                                    </ul>
                                </div> :
                                field.type == "select-http" ?
                                    <>
                                        <bds-typo variant="fs-16">kkk</bds-typo>
                                    </> :
                                    <bds-typo variant="fs-16">{data}</bds-typo>
            } */}
        </>

    return (
        <>
            <div className="items-list mt3 mb3">
                <div className="header mb3">
                    <div>
                        <h3>{resource?.name} {items.length > 0 ? `| ${items.length} ${t(items.length != 1 ? 'texts.itemPlural' : 'texts.itemSingular')}` : ""}</h3>
                    </div>

                    <div style={{ display: 'flex', gap: 10 }}>
                        {config.showBackToResourcesFromResource &&
                            <Button
                                text={t('button.back')}
                                variant="ghost"
                                arrow={false}
                                disabled={false}
                                onClick={() => setResourceOpened(null)}
                            />
                        }

                        {config?.showExport &&
                            <Button
                                text={t('button.export')}
                                variant="ghost"
                                arrow={false}
                                disabled={false}
                                onClick={() => {
                                    setAlertOpened(() => true)
                                    setAlert({
                                        header: t('texts.attention'),
                                        body: t('messages.whatExport'),
                                        actions: [
                                            {
                                                variant: "secondary",
                                                bold: "true",
                                                title: t('button.cancel'),
                                                onClick: () => setAlertOpened(false)
                                            },
                                            {
                                                variant: "primary",
                                                bold: "true",
                                                title: t('texts.itemsAndStructure'),
                                                onClick: async () => {
                                                    download(resource.name, { items, resource })
                                                }
                                            },
                                            {
                                                variant: "primary",
                                                bold: "true",
                                                title: t('texts.justStructure'),
                                                onClick: async () => {
                                                    download(resource.name, { resource })
                                                }
                                            },
                                            {
                                                variant: "primary",
                                                bold: "true",
                                                title: t('texts.justItems'),
                                                onClick: async () => {
                                                    download(resource.name, { items })
                                                }
                                            }
                                        ]
                                    })
                                }}
                            />
                        }

                        {config?.showImportItems &&
                            <>
                                {resource?.type == "collection" &&
                                    <Button
                                        text={t('button.importItems')}
                                        variant="ghost"
                                        arrow={false}
                                        disabled={false}
                                        onClick={() => setIsModalImportOpened(true)}
                                    />
                                }
                            </>
                        }

                        {resource?.type == "collection" &&
                            <Button
                                text={t('button.createItem')}
                                variant="primary"
                                arrow={false}
                                disabled={false}
                                onClick={() => setIsModalItemOpened({})}
                            />
                        }
                    </div>
                </div>


                {resource?.type == "eventTrack" &&
                    <div className="header mb3">
                        <div></div>
                        <div>
                            <DatePicker
                                startDateLimit={dateStart}
                                endDateLimit={dateEnd}
                                onChange={({ startDate, endDate }) => {
                                    console.log({ startDate, endDate })
                                    if (startDate)
                                        setDateStart(startDate)
                                    if (endDate)
                                        setDateEnd(endDate)
                                }}
                            />
                        </div>
                    </div>
                }

                {["collection", "eventTrack"].includes(resource?.type) &&
                    <>
                        {items.length == 0 ?
                            <div style={{ textAlign: "center" }}>
                                <bds-typo variant="fs-20" bold="light">Nenhum item cadastrado até o momento</bds-typo>
                            </div>
                            :
                            <div className="items">
                                <Card className="min-h-18">
                                    <table>
                                        <tbody>
                                            <tr>
                                                {resource.fields.map((field, index2) =>
                                                    <th key={index2} style={{ textAlign: "left" }}>
                                                        <bds-typo variant="fs-16" bold="bold">{field.label}</bds-typo>
                                                    </th>
                                                )}
                                                <th></th>
                                            </tr>
                                            {items.map((item, index) =>
                                                <tr key={index} className="card-resource">
                                                    {resource.fields.map((field, index2) =>
                                                        <td key={index2}>
                                                            <ShowData
                                                                field={field}
                                                                data={
                                                                    field.type == "select-http" ?
                                                                        field?.attr_text_list == "" ?
                                                                            getValueByPath(item, field.name) :
                                                                            getValueByPath(item, field.name)?.[field?.attr_text_list || field.attr_text] :
                                                                        getValueByPath(item, field.name)
                                                                }
                                                            />

                                                            {/* {(index == 0 && index2 == 5) && <>{JSON.stringify(item)}</>} */}
                                                        </td>
                                                    )}
                                                    <td style={{ display: "flex", justifyContent: "flex-end" }}>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <div style={{ display: "flex", gap: 10 }}>
                                                                {["collection"].includes(resource.type) &&
                                                                    <div className="none">
                                                                        <CopyToClipboard
                                                                            text={item[primaryKey]}
                                                                            onCopy={() =>
                                                                                showToast({
                                                                                    type: BlipPortalToastTypes.SUCCESS,
                                                                                    title: t('messages.copiedToClipboard'),
                                                                                    message: t('messages.successfullyCopied')
                                                                                })
                                                                            }
                                                                        >
                                                                            <Button
                                                                                variant="ghost"
                                                                                text={
                                                                                    <div style={{ cursor: "pointer", margin: 0 }}>
                                                                                        <img src="/key.png" />
                                                                                    </div>
                                                                                }
                                                                                arrow={false}
                                                                                disabled={false}
                                                                                onClick={() => { }}
                                                                            />
                                                                        </CopyToClipboard>
                                                                    </div>
                                                                }
                                                                {["collection"].includes(resource.type) &&
                                                                    <div className="none">
                                                                        <Button
                                                                            variant="ghost"
                                                                            icon="trash"
                                                                            arrow={false}
                                                                            disabled={false}
                                                                            onClick={() => {
                                                                                setAlertOpened(() => true)
                                                                                setAlert({
                                                                                    header: t('texts.attention'),
                                                                                    body: t('messages.deleteItem'),
                                                                                    actions: [
                                                                                        {
                                                                                            variant: "secondary",
                                                                                            bold: "true",
                                                                                            title: t('button.cancel'),
                                                                                            onClick: () => setAlertOpened(false)
                                                                                        },
                                                                                        {
                                                                                            variant: "primary",
                                                                                            bold: "true",
                                                                                            title: t('button.imSure'),
                                                                                            onClick: async () => {
                                                                                                remove(item[primaryKey])
                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                })
                                                                            }}
                                                                            size="short"
                                                                        />
                                                                    </div>
                                                                }
                                                                {["collection"].includes(resource.type) &&
                                                                    <div className="none">
                                                                        <Button
                                                                            variant="ghost"
                                                                            icon="edit"
                                                                            arrow={false}
                                                                            disabled={false}
                                                                            onClick={() => setIsModalItemOpened(item)}
                                                                        />
                                                                    </div>
                                                                }
                                                                {["eventTrack"].includes(resource.type) &&
                                                                    <div className="none">
                                                                        <Button
                                                                            variant="ghost"
                                                                            icon="search"
                                                                            arrow={false}
                                                                            disabled={false}
                                                                            onClick={() => setIsModalItemOpened(item)}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </Card>
                            </div>
                        }
                    </>
                }

                {resource?.type == "single" &&
                    <div className="single">
                        <Card className="min-h-18">
                            <table>
                                <tbody>
                                    {resource?.fields?.map((field, index) =>
                                        <tr key={index} className="card-resource">
                                            <th style={{ textAlign: "left", paddingRight: 20 }}>{field.label}</th>
                                            {/* <td>{items?.[field.name] || }</td> */}
                                            <td>
                                                <ShowData
                                                    field={field}
                                                    data={items?.[field.name]}
                                                    empty={<i>Não informado</i>}
                                                />
                                            </td>

                                            <td style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div style={{ display: "flex", gap: 10 }}>
                                                        <div className="none">
                                                            <Button
                                                                variant="ghost"
                                                                icon="edit"
                                                                arrow={false}
                                                                disabled={false}
                                                                onClick={() => setIsModalItemOpened({
                                                                    [field.name]: items?.[field.name] || null
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </Card>
                    </div>
                }

                {resource?.type == "swagger" &&
                    <Card className="min-h-18 card-swagger">
                        <iframe
                            src={`https://swagger-viewer.sharkdev.com.br/?url=${resource.file}`}
                            title={resource.title}
                        // scrolling="no"
                        />
                    </Card>
                }

                <ModalItem
                    opened={isModalItemOpened}
                    setOpened={setIsModalItemOpened}
                    resource={resource}
                    setItems={setItems}
                    items={items}
                />

                <ModalImportItems
                    opened={isModalImportOpened}
                    setOpened={setIsModalImportOpened}
                    resource={resource}
                    setItems={setItems}
                />
            </div >

            <Alert
                opened={alertOpened}
                setAlert={setAlert}
                {...alert}
            />
        </>
    )
}