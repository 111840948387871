import React, { useContext, useEffect, useState } from 'react'

import "./styles.scss"
import { showToast, startLoading, stopLoading } from '../../services/common-service'
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types'

import { IframeMessageProxy } from 'iframe-message-proxy'
import IMPContainer from '../../constants/iframe-message-proxy-container';

import { v4 as uuid } from 'uuid';
import Upload from '../Input/Upload'
import Modal from '../Modal'
import { AppContext, useApp } from '../../contexts/AppContext'
import { useBlip } from '../../contexts/BlipContext'

export default function ModalImportResource({
    opened,
    setOpened,
    resource,
    setItems,
    start
}) {
    const { t } = useContext(AppContext)
    const { config } = useApp()

    const { getResource, setResource } = useBlip()

    const [resourceUpload, setResourceUpload] = useState({})
    const [itemsUpload, setItemsUpload] = useState([])
    const [error, setError] = useState(null)

    useEffect(() => {
        if (!opened) {
            setItemsUpload([])
            setResourceUpload({})
            setError(null)
        }
    }, [opened])

    const close = () => {
        setOpened(() => null)
    }

    const submit = async () => {
        try {
            startLoading()
            setError(() => null)

            // if (itemsUpload.length == 0)
            //     throw { message: "Nenhum item foi inserido" }

            let resourcesManagerPlugin = []

            try {
                const result = await getResource(config.resourceName, "resources")

                resourcesManagerPlugin = result.resources
            } catch (error) {
                throw error
            }

            let newResourcesManagerPlugin = resourcesManagerPlugin

            let resourcesManagerPluginIndex = newResourcesManagerPlugin.findIndex(resource => resource._id == resourceUpload._id)

            if (resourcesManagerPluginIndex >= 0)
                throw { message: t('messages.resourceDuplicated') }

            newResourcesManagerPlugin.push(resourceUpload)

            await setResource(config.resourceName, { resources: newResourcesManagerPlugin })

            let typeItems = Array.isArray(itemsUpload) ? "collection" : "single"

            if (
                (typeItems == "collection" && itemsUpload.length > 0) ||
                (typeItems == "single" && Object.keys(itemsUpload).length > 0)
            ) {
                let newItems = typeItems == "collection" ? [] : {}

                try {
                    const result = await getResource(resourceUpload.key, "items")
                    // console.log(result)

                    newItems = result?.items
                } catch (error) {
                    throw error
                }

                newItems = typeItems == "collection" ?
                    [...newItems, ...itemsUpload] :
                    { ...newItems, ...itemsUpload }


                await setResource(resourceUpload.key, typeItems == "collection" ? { items: newItems } : newItems)
            }

            close()
            start()
        } catch (error) {
            // console.error(error)
            setError(() => error?.message)
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
        }
    }

    const onChangeUpload = (event) => {
        let files = event.detail.value

        if (files.length == 0)
            return

        let file = files[0]

        const reader = new FileReader();

        reader.onload = (e) => {
            const content = e.target.result;
            importDocument(content)
        };

        reader.readAsText(file);
    }

    const importDocument = (object) => {
        try {
            object = JSON.parse(object)
            if (typeof object != "object") throw { message: "Erro no envio. Confira seu arquivo" }
            if (!object.resource) throw { message: `O arquivo deve possuir o atributo "resource"` }
            // if (!Array.isArray(object.items)) throw { message: `O parâmetro "items" deve possuir um array` }

            let { resource, items } = object

            if (items && Array.isArray(items)) {
                for (let item of items) {
                    if (!item._id)
                        item._id = uuid()
                }
            }

            setResourceUpload({
                _id: uuid(),
                ...resource
            })
            setItemsUpload(items)
        } catch (error) {
            // console.error(error)
            setError(() => error?.message)
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        }
    }

    return (
        <Modal open={opened} close={close} close-button="false" size="dynamic">
            <div className="modal-resources">
                <div className="modal-body">

                    {/* {JSON.stringify(error || {})} */}

                    <Upload
                        accept=".json"
                        error={error}
                        multiple={false}
                        titleName="Arquivo JSON"
                        subtitle={`Utilize um arquivo de importação. É necessário que o array esteja no atributo "itens"`}
                        onChange={onChangeUpload}
                    />

                </div>

                {/* {JSON.stringify(data)} */}

                {/* {JSON.stringify(resourceUpload)} */}
                {/* <bds-modal-action> */}
                <div className="flex justify-end" style={{ width: "100%", zIndex: 0, gap: 10 }}>
                    <bds-button variant="secondary" onClick={close}>{t('button.cancel')}</bds-button>
                    <bds-button onClick={submit} disabled={Object.keys(resourceUpload || {}).length == 0}>{t('button.done')}</bds-button>
                </div>
                {/* </bds-modal-action> */}
            </div>
        </Modal>
    )
}