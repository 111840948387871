import React, { useContext, useEffect, useState } from 'react'

import "./styles.scss"
import Button from '../Button'
import ModalResource from '../ModalResource'
import Card from '../Card/Card'
import { showToast, startLoading, stopLoading } from '../../services/common-service'
import { IframeMessageProxy } from 'iframe-message-proxy'
import IMPContainer from '../../constants/iframe-message-proxy-container';
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types'
import Alert from '../Alert'
import { v4 as uuid } from 'uuid';
import ModalImportResource from '../ModalImportResource'
import { AppContext, useApp } from '../../contexts/AppContext'
import { useSharkPlugins } from '../../contexts/SharkPluginsProvider'
import { useBlip } from '../../contexts/BlipContext'

export default function ResourcesList({
    setResourceOpened
}) {
    const { t } = useContext(AppContext)

    const { getResource, setResource } = useBlip()

    const { sendAction } = useSharkPlugins()
    const { config } = useApp()

    const [resources, setResources] = useState([])
    const [isModalOpened, setIsModalOpened] = useState(null)
    const [isModalImportOpened, setIsModalImportOpened] = useState(null)

    const [alertOpened, setAlertOpened] = useState(false)
    const [alert, setAlert] = useState({})

    useEffect(() => {
        start()
    }, [])

    const getResourcesManagerPlugin = async () => {
        try {
            const result = await getResource(config.resourceName, "resources")

            let { resources } = result


            let defaultResource;

            for (let resource of resources) {
                if (!resource.source)
                    resources.source = "resource"

                if (config.defaultResourceOpened && resource.key == config.defaultResourceOpened)
                    setResourceOpened(resource)
            }

            return resources
        } catch (error) {
            throw error
        }
    }

    const start = async () => {
        try {
            startLoading()

            let resources = await getResourcesManagerPlugin()

            sendAction({
                type: "information",
                action: "resourcesQuantity",
                value: resources.length
            })

            setResources(resources)
        } catch (error) {
            // console.error(error)
        } finally {
            stopLoading()
        }
    }


    const remove = async (_id) => {
        try {
            startLoading()

            let resourcesManagerPlugin = []

            try {
                let response = await getResource(config.resourceName)

                resourcesManagerPlugin = response?.resources
            } catch (error) {
                if (typeof error == "string") {
                    error = JSON.parse(error)
                    if (error?.message && typeof error.message == "string")
                        error.message = JSON.parse(error.message)
                }

                // console.log("error", error)

                if (error?.message?.reason?.code == 67) {
                    resourcesManagerPlugin = []
                } else
                    throw error
            }

            // console.log(config.resourceName, resourcesManagerPlugin)
            let newResourcesManagerPlugin = resourcesManagerPlugin.filter(resource => resource._id != _id)

            await setResource(config.resourceName, { resources: newResourcesManagerPlugin })

            setResources(newResourcesManagerPlugin)
        } catch (error) {
            // console.log(JSON.parse(error))
            // console.error(error)
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
            setAlertOpened(false)
        }
    }

    return (
        <>
            <div className="resources-list mt3 mb5">
                <div className="header mb3">
                    <h3>{resources.length > 0 ? `${resources.length} ${t('texts.resource')}${resources.length > 1 ? "s" : ""}` : ""}</h3>

                    <div style={{ display: 'flex', gap: 10 }}>
                        {config?.showImportResource &&
                            <Button
                                text={t('button.import')}
                                variant="ghost"
                                arrow={false}
                                disabled={false}
                                onClick={() => setIsModalImportOpened(true)}
                            />
                        }

                        {config?.showCreateResource &&
                            <Button
                                text={t('button.createResource')}
                                variant="primary"
                                arrow={false}
                                disabled={false}
                                onClick={() => setIsModalOpened({})}
                            />
                        }

                        {/* <Button
                            text={"Cadastrar Documentação"}
                            variant="primary"
                            arrow={false}
                            disabled={false}
                            onClick={() => setIsModalOpened({})}
                        /> */}
                    </div>
                </div>

                {resources.length == 0 &&
                    <div style={{ textAlign: "center" }}>
                        <bds-typo variant="fs-20" bold="light">{t('messages.noResourceRegistered')}</bds-typo>
                    </div>
                }

                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                    {resources.map((resource, index) =>
                        <Card className="min-h-18" key={index}>
                            <div className="card-resource" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div>
                                    <bds-typo variant="fs-20" bold="light" margin={false}>{resource.name}</bds-typo>
                                    {/* <bds-typo variant="fs-16" bold="light">{t('texts.key')}: {resource.key}</bds-typo> */}
                                </div>
                                <div style={{ display: "flex", gap: 10 }}>
                                    <div className="none">
                                        {config?.showDeleteResource &&
                                            <Button
                                                variant="ghost"
                                                icon="trash"
                                                arrow={false}
                                                disabled={false}
                                                onClick={() => {
                                                    setAlertOpened(() => true)
                                                    setAlert({
                                                        header: t('texts.attention'),
                                                        body: t('messages.deleteItem'),
                                                        actions: [
                                                            {
                                                                variant: "secondary",
                                                                bold: "true",
                                                                title: t('button.cancel'),
                                                                onClick: () => setAlertOpened(false)
                                                            },
                                                            {
                                                                variant: "primary",
                                                                bold: "true",
                                                                title: t('button.imSure'),
                                                                onClick: async () => {
                                                                    remove(resource._id)
                                                                }
                                                            }
                                                        ]
                                                    })
                                                }}
                                                size="short"
                                            />
                                        }
                                    </div>
                                    {config?.showCopyResource &&
                                        <div className="none">
                                            <Button
                                                variant="ghost"
                                                icon="copy"
                                                arrow={false}
                                                disabled={false}
                                                onClick={() => setIsModalOpened({
                                                    ...resource,
                                                    _id: uuid()
                                                })}
                                            />
                                        </div>
                                    }
                                    {config?.showEditResource &&
                                        <div className="none">
                                            <Button
                                                variant="ghost"
                                                icon="edit"
                                                arrow={false}
                                                disabled={false}
                                                onClick={() => setIsModalOpened(resource)}
                                            />
                                        </div>
                                    }
                                    <div>
                                        <Button
                                            variant="ghost"
                                            text={t(resource.type == "swagger" ? 'button.accessDoc' : 'button.accessData')}
                                            arrow={false}
                                            disabled={false}
                                            onClick={() => setResourceOpened(resource)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    )}
                </div>

                <ModalResource
                    opened={isModalOpened}
                    setOpened={setIsModalOpened}
                    getResourcesManagerPlugin={getResourcesManagerPlugin}
                    start={start}
                />

                <ModalImportResource
                    opened={isModalImportOpened}
                    setOpened={setIsModalImportOpened}
                    start={start}
                />
            </div>

            <Alert
                opened={alertOpened}
                setAlert={setAlert}
                {...alert}
            />
        </>
    )
}