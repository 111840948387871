import React, { useContext, useEffect, useState } from 'react'

import "./styles.scss"
import { showToast, startLoading, stopLoading } from '../../services/common-service'
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types'

import { IframeMessageProxy } from 'iframe-message-proxy'
import IMPContainer from '../../constants/iframe-message-proxy-container';

import { v4 as uuid } from 'uuid';
import Upload from '../Input/Upload'
import Alert from '../Alert'
import Modal from '../Modal'
import { AppContext } from '../../contexts/AppContext'
import { useBlip } from '../../contexts/BlipContext'

export default function ModalImportItems({
    opened,
    setOpened,
    resource,
    setItems
}) {
    const { t, i18n } = useContext(AppContext)

    const { getResource, setResource } = useBlip()

    const [itemsUpload, setItemsUpload] = useState([])
    const [error, setError] = useState(null)

    const [alertOpened, setAlertOpened] = useState(false)
    const [alert, setAlert] = useState({})

    useEffect(() => {
        if (!opened) {
            setItemsUpload([])
            setError(null)
        }
    }, [opened])

    const close = () => {
        setOpened(() => null)
    }

    const submit = async () => {
        try {
            startLoading()
            setError(() => null)

            if (itemsUpload.length == 0)
                throw { message: t('messages.noItemWasEntered') }

            let newItems = []

            try {
                const result = await getResource(resource.key, "items")
                // console.log(result)

                newItems = result?.items
            } catch (error) {
                throw error
            }

            // Verificar registros com ID iguais
            if (itemsUpload.some(item => newItems.some(item2 => item2._id == item._id))) {
                setAlertOpened(() => true)
                setAlert({
                    header: t('texts.attention'),
                    body: t('messages.duplicatedIDs'),
                    actions: [
                        {
                            variant: "secondary",
                            bold: "true",
                            title: t('button.cancelImport'),
                            onClick: () => setAlertOpened(false)
                        },
                        {
                            variant: "primary",
                            bold: "true",
                            title: t('button.replaceWithNewOnes'),
                            onClick: async () => {
                                for (let item of itemsUpload) {
                                    let index = newItems.findIndex(item2 => item2._id == item._id)
                                    if (index >= 0) {
                                        newItems.splice(index, 1)
                                    }
                                }

                                setAlertOpened(false)
                                upload([...newItems, ...itemsUpload])
                            }
                        },
                        {
                            variant: "primary",
                            bold: "true",
                            title: t('button.keepThoseAlreadyRegistered'),
                            onClick: async () => {
                                let newItemsUpload = []

                                for (let item of itemsUpload) {
                                    let index = newItems.findIndex(item2 => item2._id == item._id)
                                    if (index < 0) {
                                        newItemsUpload.push(item)
                                    }
                                }

                                setAlertOpened(false)
                                upload([...newItems, ...newItemsUpload])
                            }
                        }
                    ]
                })
            } else
                upload([...newItems, ...itemsUpload])
        } catch (error) {
            // console.error(error)
            setError(() => error?.message)
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
        }
    }

    const upload = async (newItems) => {
        await setResource(resource.key, { items: newItems })
        setItems(newItems)
        close()
    }

    const onChangeUpload = (event) => {
        let files = event.detail.value

        if (files.length == 0)
            return

        let file = files[0]

        const reader = new FileReader();

        reader.onload = (e) => {
            const content = e.target.result;
            importDocument(content)
        };

        reader.readAsText(file);
    }

    const importDocument = (object) => {
        try {
            object = JSON.parse(object)
            if (typeof object != "object") throw { message: "Erro no envio. Confira seu arquivo" }
            if (!object.items) throw { message: `O arquivo deve possuir o atributo "items"` }
            if (!Array.isArray(object.items)) throw { message: `O parâmetro "items" deve possuir um array` }

            let { items } = object

            for (let item of items) {
                if (!item._id)
                    item._id = uuid()
            }

            setItemsUpload(items)
        } catch (error) {
            // console.error(error)
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        }
    }

    return (
        <>
            <Modal open={opened} close={() => setOpened(() => null)} close-button="false" size="dynamic">
                <div className="modal-resources">
                    <div className="modal-body">

                        <Upload
                            accept=".json"
                            error={error}
                            multiple={false}
                            titleName={t('texts.jsonFile')}
                            subtitle={t('messages.useImportFile')}
                            onChange={onChangeUpload}
                            language={({
                                pt: "pt_BR",
                                en: "en_US",
                                es: "es_ES"
                            })[i18n.language] || "pt_BR"}
                        />

                    </div>

                    {/* {JSON.stringify(data)} */}

                    {/* <bds-modal-action> */}
                    <div className="flex justify-end" style={{ width: "100%", zIndex: 0 }}>
                        <bds-button variant="secondary" onClick={close}>{t('button.cancel')}</bds-button>
                        <bds-button onClick={submit} disabled={itemsUpload.length == 0}>{t('button.done')}</bds-button>
                    </div>
                    {/* </bds-modal-action> */}
                </div>
            </Modal>

            <Alert
                opened={alertOpened}
                setAlert={setAlert}
                {...alert}
            />
        </>
    )
}