import React, { useEffect, useRef } from 'react'

export default function InputChips({
    chips,
    danger,
    disabled,
    disabledSubmit,
    duplicated,
    errorMessage,
    helperMessage,
    icon,
    inputName,
    label,
    placeholder,
    type,
    value,
    maxLength,
    onChange
}) {
    const ref = useRef()

    useEffect(() => {
        const { current } = ref;
        current.addEventListener('bdsChange', handleOnChange);
        current.addEventListener('bdsChangeChips', handleOnChangeChips);
        current.addEventListener('bdsSubmit', handleOnSubmit);

        return () => {
            current.removeEventListener('bdsChange', handleOnChange);
            current.removeEventListener('bdsChangeChips', handleOnChangeChips);
            current.removeEventListener('bdsSubmit', handleOnSubmit);
        };
    }, []);

    useEffect(() => {
        // console.log("value mudou", value)
        onChangeValue()
    }, [value])

    const onChangeValue = async () => {
        for (let item of value) {
            await ref.current.add(item)
        }
    }

    const handleOnChange = (event) => {
        // console.log("onChange", event)
        if (onChange)
            onChange(event.detail)
    }

    const handleOnChangeChips = (event) => {
        // console.log("handleOnChangeChips", event, event.datail)
        if (onChange)
            onChange(event.detail)
    }

    const handleOnSubmit = (event) => {
        // console.log("handleOnSubmit", event)
        if (onChange)
            onChange(event.detail)
    }

    return (
        <div>
            <bds-input-chips
                ref={ref}
                chips={chips}
                danger={danger}
                disabled={disabled}
                disabled-submit={disabledSubmit}
                duplicated={duplicated}
                error-message={errorMessage}
                helper-message={helperMessage}
                icon={icon}
                input-name={inputName}
                label={label}
                placeholder={placeholder}
                type={type}
                value={value}
                maxLength={maxLength}
            />
        </div>
    )
}