import React, { useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Header from './components/Header';
import Copyright from '../../components/Copyright';
import CustomTab from '../../CustomTab';
import ResourcesList from '../../components/ResourcesList';
import Resource from '../../components/Resource';

import { AppContext, useApp } from '../../contexts/AppContext';

import "./styles.scss"

const PAGE_ICON = 'plugin';
const BLANK = '_blank';

const Home = () => {
    const { t, i18n } = useContext(AppContext)

    const { config } = useApp()

    const [customTab, setCustomTab] = useState(null)

    const [resourceOpened, setResourceOpened] = useState(null)

    const all = useParams()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // const getResource = async (key, parameter) => {
    //     try {
    //         const result = await IframeMessageProxy.sendMessage({
    //             action: IMPContainer.Actions.SEND_COMMAND,
    //             content: {
    //                 command: {
    //                     method: IMPContainer.CommandMethods.GET,
    //                     uri: `/resources/${key}`
    //                 },
    //                 // destination: IMPContainer.Destinations.BLIP_SERVICE
    //             }
    //         });

    //         return result.response
    //     } catch (error) {
    //         console.error(error)
    //         if (typeof error == "string") {
    //             error = JSON.parse(error)
    //             if (error?.message && typeof error.message == "string")
    //                 error.message = JSON.parse(error.message)
    //         }

    //         console.error("errorrrr", error)

    //         if (error?.code == 67 || error?.message?.reason?.code == 67)
    //             return parameter ? { [parameter]: [] } : {}

    //         throw error
    //     }
    // }

    return (
        <div className="flex" style={{ height: "100vh", overflowX: "auto" }}>
            <div className="home-container ph1 ph4-m ph5-ns pb5 relative" style={{ flex: 1 }}>
                {(!resourceOpened || (resourceOpened && config?.showHeaderWithResourceOpen)) &&
                    <Header
                        title={config.customTitle || t('title.homePage')}
                        icon={PAGE_ICON}
                        onClick={() => window.open(t('link.doc'), BLANK)}
                    />
                }

                {resourceOpened ?
                    <Resource
                        resource={resourceOpened}
                        setResourceOpened={setResourceOpened}
                    />
                    :
                    <ResourcesList
                        setResourceOpened={setResourceOpened}
                    />
                }

                {(!resourceOpened || (resourceOpened && config?.showCopyrightWithResourceOpen)) &&
                    <Copyright />
                }
            </div>

            <CustomTab
                opened={customTab != null}
                setCustomTab={setCustomTab}
                {...customTab}
            />
        </div>
    );
};

Home.propTypes = {};

export default Home;
