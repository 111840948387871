const translations = {
    "title": {
        "homePage": "Resource Manager",
        "exemples": "Examples",
        "systemInfo": "System Information",
        "userInfo": "User Information",
        "connectDatabase": "Connect Database",
        "description": "Use the form below to provide the MongoDB database connection data."
    },
    "link": {
        "doc": "https://docs.sharkdev.com.br/blip/gerenciador_de_recursos/en",
        "blipDataExample": "Get data from Blip",
        "swrExemple": "Fetch data with SWR",
        "pluginSettings": "Settings"
    },
    "button": {
        "repository": "Visit Repository",
        "connect": "Connect",
        "disconnect": "Disconnect",
        "reconnect": "Reconnect",
        "document": "Visit Documentation",
        "cancel": "Cancel",
        "import": "Import",
        "export": "Export",
        "createResource": "Create Resource",
        "accessData": "Access Data",
        "importItems": "Import Items",
        "createItem": "Create Item",
        "back": "Back",
        "done": "Done",
        "newField": "New Field",
        "newFieldObject": "New Object Field",
        "newOption": "New Option",
        "imSure": "Yes, I'm sure",
        "cancelImport": "Cancel Import",
        "replaceWithNewOnes": "Replace with new ones",
        "keepThoseAlreadyRegistered": "Keep those already registered"
    },
    "texts": {
        "itemSigular": "item",
        "itemPlural": "items",
        "key": "Key",
        "attention": "Attention",
        "resource": "resource",
        "basicInformation": "Basic Information",
        "fields": "Fields",
        "aboutFieldName": "Name used for display purposes only",
        "aboutFieldKey": "Used to retrieve the content value by the server",
        "placeholderName": "What name would you like to give to this resource?",
        "placeholderKey": "Resource key",
        "placeholderResourceType": "What is the resource type?",
        "objectFields": "Object Fields",
        "options": "Options",
        "title": "Title",
        "fieldTitle": "Field Title",
        "fieldKey": "Field Key",
        "type": "Type",
        "collection": "Collection",
        "single": "Single",
        "text": "Text",
        "select": "Selection",
        "textList": "Text List",
        "objectList": "Object List",
        "onOff": "On/Off",
        "file": "File",
        "resourceType": "Resource Type",
        "add": "Add",
        "itemsAndStructure": "Items and Structure",
        "justStructure": "Structure Only",
        "justItems": "Items Only",
        "jsonFile": "JSON File"
    },
    "menu": {
        "goBack": "Go Back"
    },
    "name": "Name",
    "shortName": "Short Name",
    "email": "E-mail",
    "phone": "Phone",
    "identity": "Identifier",
    "identifier": "Identifier",
    "language": "Language",
    "accessKey": "Access Key",
    "template": "Template",
    "creationDate": "Creation Date",
    "appresentation": "<b>Resource Manager</b> has arrived to make the life of developers easier when they need to connect to a Mongo database. No more intermediary APIs! Now you can rely on <b>Resource Manager</b> 😍<br /><br />With this extension, you can connect Blip to your MongoDB database by providing the connection data and start using it on the Builder right away.<br /><br />It's easy and fast!",
    "guidelines": {
        // guideline_1: "Test 1",
        // guideline_2: "Test 2",
    },
    "instance": {
        "connection": {
            "connected": "Database connected",
            "disconnected": "Database disconnected",
            "failed": "Connection to the Database failed"
        }
    },
    "messages": {
        "created": "Instance created successfully",
        "updated": "Instance updated successfully",
        "connected": "Instance connected successfully",
        "disconnected": "Instance disconnected successfully",
        "failed_connection": "Connection to the Database failed",
        "copy_to_clipboard": "Copied to clipboard!",
        "user_admin": "Only administrators can access the extension",
        "deleteItem": "Are you sure you want to delete this item? You won't be able to recover it later.",
        "copiedToClipboard": "Copied to clipboard!",
        "successfullyCopied": "The item ID was successfully copied.",
        "resourceDuplicated": "Another resource with the same ID already exists.",
        "noResourceRegistered": "No resource registered at the moment",
        "whatExport": "What do you want to export?",
        "useImportFile": "Use an import file. The array must be in the 'items' attribute",
        "noItemWasEntered": "No item was entered",
        "duplicatedIDs": "We found records with identical IDs. How do you want to proceed?"
    },
    "forms": {
        "required": "This field is required"
    },
    "developedBy": "Developed by"
}


export default translations;
