import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../../../components/PageHeader';
import Button from '../../../../components/Button';
import { AppContext, useApp } from '../../../../contexts/AppContext';
import { useSharkPlugins } from '../../../../contexts/SharkPluginsProvider';

const Header = ({
    title,
    icon,
    onClick
}) => {
    const { t } = useContext(AppContext)

    const { sendAction } = useSharkPlugins()
    const { config } = useApp()

    const renderOptions = () => (
        <div className="ml3 flex">
            {config.showDocs &&
                <Button
                    text={t('button.document')}
                    icon="link"
                    variant="ghost"
                    onClick={() => {
                        onClick()
                        sendAction({
                            type: "event",
                            action: "visitDocumentation",
                            value: 1
                        })
                    }}
                    dataTestId="repository-link"
                />
            }
        </div>
    );

    return (
        <PageHeader
            title={title}
            icon={icon}
            relatedOptions={renderOptions()}
        />
    );
};

Header.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func
};

export default Header;
