const translations = {
    "title": {
        "homePage": "Gestor de Recursos",
        "exemples": "Ejemplos",
        "systemInfo": "Información del Sistema",
        "userInfo": "Información del Usuario",
        "connectDatabase": "Conectar a la Base de Datos",
        "description": "Utiliza el formulario a continuación para proporcionar los datos de conexión a la base de datos de MongoDB."
    },
    "link": {
        "doc": "https://docs.sharkdev.com.br/blip/gerenciador_de_recursos/es",
        "blipDataExample": "Obtener datos de Blip",
        "swrExemple": "Obtener datos con SWR",
        "pluginSettings": "Configuraciones"
    },
    "button": {
        "repository": "Visitar Repositorio",
        "connect": "Conectar",
        "disconnect": "Desconectar",
        "reconnect": "Reconectar",
        "document": "Visitar Documentación",
        "cancel": "Cancelar",
        "import": "Importar",
        "export": "Exportar",
        "createResource": "Crear Recurso",
        "accessData": "Acceder a los Datos",
        "importItems": "Importar Elementos",
        "createItem": "Crear Elemento",
        "back": "Volver",
        "done": "Hecho",
        "newField": "Nuevo Campo",
        "newFieldObject": "Nuevo Campo de Objeto",
        "newOption": "Nueva Opción",
        "imSure": "Sí, estoy seguro/a",
        "cancelImport": "Cancelar Importación",
        "replaceWithNewOnes": "Reemplazar con los nuevos",
        "keepThoseAlreadyRegistered": "Mantener los ya registrados"
    },
    "texts": {
        "itemSigular": "elemento",
        "itemPlural": "elementos",
        "key": "Clave",
        "attention": "Atención",
        "resource": "recurso",
        "basicInformation": "Información Básica",
        "fields": "Campos",
        "aboutFieldName": "Nombre utilizado solo para fines de visualización",
        "aboutFieldKey": "Utilizado para recuperar el valor del contenido por el servidor",
        "placeholderName": "¿Qué nombre te gustaría dar a este recurso?",
        "placeholderKey": "Clave del recurso",
        "placeholderResourceType": "¿Cuál es el tipo de recurso?",
        "objectFields": "Campos del Objeto",
        "options": "Opciones",
        "title": "Título",
        "fieldTitle": "Título del Campo",
        "fieldKey": "Clave del Campo",
        "type": "Tipo",
        "collection": "Colección",
        "single": "Único",
        "text": "Texto",
        "select": "Selección",
        "textList": "Lista de Texto",
        "objectList": "Lista de Objetos",
        "onOff": "Encendido/Apagado",
        "file": "Archivo",
        "resourceType": "Tipo de Recurso",
        "add": "Agregar",
        "itemsAndStructure": "Elementos y Estructura",
        "justStructure": "Solo Estructura",
        "justItems": "Solo Elementos",
        "jsonFile": "Archivo JSON"
    },
    "menu": {
        "goBack": "Volver"
    },
    "name": "Nombre",
    "shortName": "Nombre Corto",
    "email": "Correo Electrónico",
    "phone": "Teléfono",
    "identity": "Identificador",
    "identifier": "Identificador",
    "language": "Idioma",
    "accessKey": "Clave de Acceso",
    "template": "Plantilla",
    "creationDate": "Fecha de Creación",
    "appresentation": "<b>Gestor de Recursos</b> ha llegado para facilitar la vida del desarrollador que necesita conectarse a una base de datos de Mongo. ¡Nada de API intermedia! Ahora puedes contar con <b>Gestor de Recursos</b> 😍<br /><br />Con esta extensión, puedes conectar Blip a tu base de datos de MongoDB proporcionando los datos de conexión y comenzar a usarla en Builder de inmediato.<br /><br />¡Es fácil y rápido!",
    "guidelines": {
        // guideline_1: "Test 1",
        // guideline_2: "Test 2",
    },
    "instance": {
        "connection": {
            "connected": "Base de datos conectada",
            "disconnected": "Base de datos desconectada",
            "failed": "La conexión a la base de datos falló"
        }
    },
    "messages": {
        "created": "Instancia creada exitosamente",
        "updated": "Instancia actualizada exitosamente",
        "connected": "Instancia conectada exitosamente",
        "disconnected": "Instancia desconectada exitosamente",
        "failed_connection": "La conexión a la base de datos falló",
        "copy_to_clipboard": "Copiado al portapapeles",
        "user_admin": "Solo los administradores pueden acceder a la extensión",
        "deleteItem": "¿Estás seguro/a de que deseas eliminar este elemento? No podrás recuperarlo después.",
        "copiedToClipboard": "Copiado al portapapeles",
        "successfullyCopied": "El ID del elemento se copió exitosamente.",
        "resourceDuplicated": "Ya existe otro recurso con el mismo ID.",
        "noResourceRegistered": "No hay recursos registrados en este momento",
        "whatExport": "¿Qué deseas exportar?",
        "useImportFile": "Utiliza un archivo de importación. El array debe estar en el atributo 'items'",
        "noItemWasEntered": "No se ingresó ningún elemento",
        "duplicatedIDs": "Se encontraron registros con IDs idénticos. ¿Cómo deseas proceder?"
    },
    "forms": {
        "required": "Este campo es obligatorio"
    },
    "developedBy": "Desarrollado por"
}

export default translations;
