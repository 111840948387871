import { IframeMessageProxy } from "iframe-message-proxy";
import IMPContainer from '../constants/iframe-message-proxy-container';
import apiBlip from "./blip.api";

export const authorizationEncode = (shortName, accessKey) => {
    return `Key ${btoa(`${shortName}:${atob(accessKey)}`)}`
}

export const executeCommand = async (command) => {
    try {
        let Authorization = apiBlip.defaults.headers.common['Authorization'];

        if (Authorization) {
            let { data } = await apiBlip.post("/commands", { id: "{{$guid}}", ...command })
            return data
        } else {
            const result = await IframeMessageProxy.sendMessage({
                action: IMPContainer.Actions.SEND_COMMAND,
                content: {
                    command
                }
            });

            return result.response
        }
    } catch (error) {
        throw error
    }
}


export const getTenants = async () => {
    try {
        let result = await IframeMessageProxy.sendMessage({
            action: IMPContainer.Actions.SEND_COMMAND,
            content: {
                command: {
                    method: IMPContainer.CommandMethods.GET,
                    to: "postmaster@portal.blip.ai",
                    uri: "/tenant-users-mine"
                },
                destination: IMPContainer.Destinations.BLIP_SERVICE,
                timeout: 30000
            }
        });

        return result.response
    } catch (error) {
        throw error
    }
}

export const getApplications = async (tenant) => {
    try {
        let result = await IframeMessageProxy.sendMessage({
            action: IMPContainer.Actions.SEND_COMMAND,
            content: {
                command: {
                    method: IMPContainer.CommandMethods.GET,
                    to: "postmaster@portal.blip.ai",
                    uri: `/tenants/${tenant}/applications`
                },
                destination: IMPContainer.Destinations.BLIP_SERVICE,
                timeout: 30000
            }
        });

        return result.response
    } catch (error) {
        throw error
    }
}

export const getUsersFromTenant = async (tenant) => {
    try {
        let result = await IframeMessageProxy.sendMessage({
            action: IMPContainer.Actions.SEND_COMMAND,
            content: {
                command: {
                    method: IMPContainer.CommandMethods.GET,
                    to: "postmaster@portal.blip.ai",
                    uri: `/tenants/${tenant}/users?$skip=0&$take=9999`
                },
                destination: IMPContainer.Destinations.BLIP_SERVICE,
                timeout: 30000
            }
        });

        return result.response
    } catch (error) {
        throw error
    }
}