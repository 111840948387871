import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IframeMessageProxy } from 'iframe-message-proxy'
import IMPContainer from '../constants/iframe-message-proxy-container';
import { executeCommand } from "../services/blip";

export const BlipContext = createContext();

export function BlipProvider({ children }) {
    const { t, i18n } = useTranslation();

    useEffect(() => {

    }, [])

    const getResource = async (key, parameter) => {
        try {
            let result = await executeCommand({
                method: IMPContainer.CommandMethods.GET,
                uri: `/resources/${key}`
            })

            // console.log("getResource", result)
            // const result = await IframeMessageProxy.sendMessage({
            //     action: IMPContainer.Actions.SEND_COMMAND,
            //     content: {
            //         command: {
            //             method: IMPContainer.CommandMethods.GET,
            //             uri: `/resources/${key}`
            //         },
            //         // destination: IMPContainer.Destinations.BLIP_SERVICE
            //     }
            // });

            if (result.status) {
                if (result.status != "success")
                    throw result.reason

                return result.resource
            } else
                return result
        } catch (error) {
            // console.error(error)
            if (typeof error == "string") {
                error = JSON.parse(error)
                if (error?.message && typeof error.message == "string")
                    error.message = JSON.parse(error.message)
            }

            if (error?.code == 67 || error?.message?.reason?.code == 67)
                return parameter ? { [parameter]: [] } : {}

            throw error
        }
    }

    const setResource = async (key, value, type = "application/json") => {
        try {
            // console.log("{ setResource }", key, value, type)
            let result = await executeCommand({
                method: IMPContainer.CommandMethods.SET,
                uri: `/resources/${key}`,
                type,
                resource: value
            })

            return result
            // const result = await IframeMessageProxy.sendMessage({
            //     action: IMPContainer.Actions.SEND_COMMAND,
            //     content: {
            //         command: {
            //             method: IMPContainer.CommandMethods.SET,
            //             uri: `/resources/${key}`,
            //             type,
            //             resource: value
            //         },
            //         // destination: IMPContainer.Destinations.BLIP_SERVICE
            //     }
            // });

            // return result.response
        } catch (error) {
            // console.error("erro setResource", error)
            if (typeof error == "string") {
                error = JSON.parse(error)
                if (error?.message && typeof error.message == "string")
                    error.message = JSON.parse(error.message)
            }

            throw error
        }
    }

    const getEventCategories = async ({ take = 10000 } = {}) => {
        try {
            let result = await executeCommand({
                method: IMPContainer.CommandMethods.GET,
                to: "postmaster@analytics.msging.net",
                uri: `/event-track?$take=${take}`
            })

            return result?.items || []
            // const result = await IframeMessageProxy.sendMessage({
            //     action: IMPContainer.Actions.SEND_COMMAND,
            //     content: {
            //         command: {
            //             method: IMPContainer.CommandMethods.GET,
            //             to: "postmaster@analytics.msging.net",
            //             uri: `/event-track?$take=${take}`
            //         }
            //     }
            // });

            // return result?.response?.items || []
        } catch (error) {
            return []
        }
    }

    const getEventDetails = async (category, { take = 10000, startDate, endDate } = {}) => {
        try {
            let result = await executeCommand({
                method: IMPContainer.CommandMethods.GET,
                to: "postmaster@analytics.msging.net",
                uri: `/event-track/${category}?$take=${take}&startDate=${startDate}&endDate=${endDate}`
            })

            return result?.items || []
            // const result = await IframeMessageProxy.sendMessage({
            //     action: IMPContainer.Actions.SEND_COMMAND,
            //     content: {
            //         command: {
            //             method: IMPContainer.CommandMethods.GET,
            //             to: "postmaster@analytics.msging.net",
            //             uri: `/event-track/${category}?$take=${take}&startDate=${startDate}&endDate=${endDate}`
            //         }
            //     }
            // });

            // return result?.response?.items || []
        } catch (error) {
            return []
        }
    }

    return (
        <BlipContext.Provider
            value={{
                getResource,
                setResource,
                getEventCategories,
                getEventDetails
            }}
        >
            {children}
        </BlipContext.Provider>
    );
};

export function useBlip() {
    const context = useContext(BlipContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}
