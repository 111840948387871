import React, { useRef, useEffect } from 'react'

export default function Modal({
    open,
    close,
    ...rest
}) {
    const ref = useRef(null)

    useEffect(() => {
        const observer = new MutationObserver((mutationsList) => {
            if (close && !ref.current.open)
                close()
            // Faça qualquer ação necessária quando o atributo mudar
        });

        // Configuração do observer para observar mudanças no atributo 'meu-atributo'
        const config = {
            attributes: true,
            attributeFilter: ['open'],
        };

        // Inicia a observação do atributo 'meu-atributo' no elemento referenciado pelo 'ref'
        observer.observe(ref.current, config);

        // Cleanup function para parar a observação quando o componente for desmontado
        return () => {
            observer.disconnect();
        };
    }, [])

    const handleModalChanged = (event) => {
        // console.log("handleModalChanged", event)
    }

    return (
        <bds-modal
            ref={ref}
            open={open}
            {...rest}
        >

        </bds-modal>
    )
}