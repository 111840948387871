import React, { useRef, useEffect } from 'react'
import { v4 as uuid } from 'uuid';

export default function Upload({
    accept,
    error,
    multiple,
    titleName,
    subtitle,
    onChange,
    onDelete
}) {
    useEffect(() => {
        window.addEventListener('bdsUploadChange', onChange);
        // window.addEventListener('bdsUploadDelete', onDelete?.(event.detail.value));

        return () => {
            window.removeEventListener('bdsUploadChange', onChange);
            // window.removeEventListener('bdsUploadDelete', (event) => onDelete?.(event.detail.value));
        }
    }, [])

    return (
        <div>
            <bds-upload
                accept={accept}
                error={error}
                multiple={multiple}
                title-name={titleName}
                subtitle={subtitle}
            />
        </div>
    )
}